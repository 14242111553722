import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useTranslation} from "react-i18next";
import {API_URL} from "../../constants/variables";

function HomeFacts() {
    const [statistic, setStatistic] = useState({});
    const language = localStorage.getItem('lang')
    const {t, i18} = useTranslation();
    useEffect(() => {
        axios.get(API_URL + "/statistic").then(res => {
            setStatistic(res.data.statistic[0]);
            console.log(res.data)
        })
    }, [])

    return (
        <div>
            <div className="row-facts-1 parallax parallax-overlay">
                <div className="bg-parallax-overlay overlay-black"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop="104" data-mobi="60" data-smobi="60"></div>
                        </div>
                        <div className="col-md-3">
                            <div className="themesflat-animation-block"
                                 data-animate="fadeInUpSmall"
                                 data-duration="1s"
                                 data-delay="0"
                                 data-position="80%">
                                <div className="themesflat-counter style-3 mobi-center clearfix icon-top margin-top--27">
                                    <div className="inner">
                                        <div className="text-wrap">
                                            <div className="number-wrap font-heading">
                                                <span className="prefix">{t('Fact.name')}<br/></span>
                                                <span className="number accent"></span>
                                                <span className="suffix">{statistic && language === 'uz' ? statistic.title_statistic_uz : statistic && statistic.title_statistic_ru}</span>
                                            </div>
                                            <div className="sep"></div>
                                            <h3 className="heading">{statistic && language === 'uz' ? statistic.text_statistic_uz : statistic && statistic.text_statistic_ru}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop="0" data-mobi="40" data-smobi="40"></div>
                        </div>
                        <div className="col-md-3">
                            <div className="themesflat-animation-block"
                                 data-animate="fadeInUpSmall"
                                 data-duration="1s"
                                 data-delay="0.15s"
                                 data-position="80%">
                                <div
                                    className="themesflat-counter style-2 clearfix icon-top text-center">
                                    <div className="inner">
                                        <div className="text-wrap">
                                            <div className="number-wrap font-heading">
                                                <span className="prefix"></span>
                                                <span className="number accent"
                                                      data-speed="3000"
                                                      data-to={statistic && language === 'uz' ? statistic.count_marriages_uz : statistic && statistic.count_marriages_ru}
                                                      data-inviewport="yes"></span>
                                                <span className="suffix">+</span>
                                            </div>
                                            <div className="sep"></div>
                                            <h3 className="heading text-uppercase">{statistic && language === 'uz' ? statistic.title_marriages_uz : statistic && statistic.title_marriages_ru}</h3>
                                        </div>
                                        <div className="border-right-2"> </div>
                                    </div>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop="0" data-mobi="40" data-smobi="40"></div>
                        </div>
                        <div className="col-md-3">
                            <div className="themesflat-animation-block"
                                 data-animate="fadeInUpSmall"
                                 data-duration="1s"
                                 data-delay="0.3s"
                                 data-position="80%">
                                <div
                                    className="themesflat-counter style-2 clearfix icon-top text-center">
                                    <div className="inner">
                                        <div className="text-wrap">
                                            <div className="number-wrap font-heading">
                                                <span className="prefix"></span>
                                                <span className="number accent"
                                                      data-speed="3000"
                                                      data-to={statistic && language === 'uz' ? statistic.count_born_uz : statistic && statistic.count_born_ru}
                                                      data-inviewport="yes"></span>
                                                <span className="suffix">+</span>
                                            </div>
                                            <div className="sep"></div>
                                            <h3 className="heading text-uppercase">{statistic && language === 'uz' ? statistic.title_born_uz : statistic && statistic.title_born_ru}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop="0" data-mobi="40" data-smobi="40"></div>
                        </div>
                        <div className="col-md-3">
                            <div className="themesflat-animation-block"
                                 data-animate="fadeInUpSmall"
                                 data-duration="1s"
                                 data-delay="0.45s"
                                 data-position="80%">
                                <div className="themesflat-counter style-2 clearfix icon-top text-center">
                                    <div className="inner">
                                        <div className="text-wrap">
                                            <div className="number-wrap font-heading">
                                                <span className="prefix"></span>
                                                <span className="number accent"
                                                      data-speed="3000"
                                                      data-to={statistic && language === 'uz' ? statistic.count_happy_uz : statistic && statistic.count_happy_ru}
                                                      data-inviewport="yes"></span>
                                                <span className="suffix">+</span>
                                            </div>
                                            <div className="sep"></div>
                                            <h3 className="heading text-uppercase">{statistic && language === 'uz' ? statistic.title_happy_uz : statistic && statistic.title_happy_ru}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="themesflat-spacer clearfix" data-desktop="72" data-mobi="60" data-smobi="60"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HomeFacts;