import { useHistory } from "react-router";
import FooterFotoPotok from "./FooterFotoPotok";
import FooterTegs from "./FooterTegs";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const history = useHistory();
  // const goService = () => {
  //     history.push('/service')
  // }
  //jdhsajdhjsahdj
  const { t } = useTranslation();
  return (
    <div id="wrapper" className="animsition">
      <div id="page" className="clearfix">
        <footer id="footer">
          <div
            id="footer-widgets"
            className="themesflat-container title-style-1"
          >
            <div className="themesflat-row gutter-30">
              <div className="span_1_of_4 col" style={{ paddingTop: "7px" }}>
                <div className="widget widget_information padding-left-7">
                  <h2 className="widget-title">
                    <span>{t("Footer.address")}</span>
                  </h2>
                  <ul>
                    <li className="address clearfix">
                      <div className="inner">
                        <i className=" finance-icon-map"> </i>
                        <span className="text">
                          Toshkent 100011, Alisher Navoiy ko'chasi 11-uy
                        </span>
                      </div>
                    </li>
                    <li className="email clearfix">
                      <div className="inner">
                        <i className=" finance-icon-Email02"> </i>
                        <span className="text">yoshoila@yoshlar.gov.uz</span>
                      </div>
                    </li>
                    <li className="phone clearfix">
                      <div className="inner">
                        <i className=" finance-icon-Phone"> </i>
                        <span className="text">
                          1093 / 9:00-18:00 (13:00-14:00 tushlik)
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="span_1_of_4 col">
                <div className="widget widget_twitter margin-top-6 padding-left-13">
                  <h2 className="widget-title">
                    <span>{t("Footer.facebook")}</span>
                  </h2>
                  <ul className="tweets clearfix">
                    <li className="item clearfix">
                      <div className="tweet-icon">
                        <i className="icon finance-icon-Facebook"> </i>
                      </div>
                      <div className="texts">
                        <h3>
                          <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Ffb.me%2Fyoshoilaplatformasi&h=AT15Wep5lDjDAoy3WrcFSbKDnhlW8C6822dzqUHG0QCaBIvskehM2idAr4v91KzVAzV3fF7boBg7264fH5MOlymduo5RJ7ObFaXMd43ac36Def7HtDKWYRpwp1PoGGrZ0bgYdw">
                            {t("Footer.facebookNews1")}
                          </a>
                        </h3>
                        <span className="tweet-time">
                          <span className="entry-date">2021 3 январ</span>
                        </span>
                      </div>
                    </li>
                    <li className="item clearfix">
                      <div className="tweet-icon">
                        <i className="icon finance-icon-Facebook"> </i>
                      </div>
                      <div className="texts">
                        <h3>
                          <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Ffb.me%2Fyoshoilaplatformasi&h=AT15Wep5lDjDAoy3WrcFSbKDnhlW8C6822dzqUHG0QCaBIvskehM2idAr4v91KzVAzV3fF7boBg7264fH5MOlymduo5RJ7ObFaXMd43ac36Def7HtDKWYRpwp1PoGGrZ0bgYdw">
                            {t("Footer.facebookNews2")}
                          </a>
                        </h3>
                        <span className="tweet-time">
                          <span className="entry-date">2021 15 январ</span>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div> 
              </div> */}
              <FooterTegs />
              <FooterFotoPotok />
            </div>
          </div>
        </footer>
        <div id="bottom" className="clearfix style-1">
          <div id="bottom-bar-inner" className="themesflat-container">
            <div className="bottom-bar-inner-wrap">
              <div className="bottom-bar-content">
                <div id="copyright" className="padding-left-9">
                  &copy;{" "}
                  <a href="https://yoshlar.gov.uz">{t("Footer.agent")}</a>
                  <br />{" "}
                  <a href="https://napaautomotive.uz/">
                    DESIGNED BY Napa Automotive.
                  </a>
                  <br />
                  {t("Footer.napa")}
                </div>
              </div>
              <div className="bottom-bar-menu">
                <ul className="bottom-nav">
                  <li className="menu-item">
                    <a href="/about">{t("Menu.menu32")}</a>
                  </li>
                  <li className="menu-item">
                    <a href="/service">{t("Menu.menu33")}</a>
                  </li>
                  <li className="menu-item">
                    <a href="/contact">{t("Menu.menu34")}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
