import React from 'react';
import '../../i18n'
import {useTranslation} from "react-i18next";
import "aos/dist/aos.css"

function HomePlatform() {
    const {t} = useTranslation();
    return (
        <div>
            <div className="themesflat-spacer clearfix" data-desktop="93" data-mobi="40" data-smobi="40"></div>
            <div className="themesflat-row row-facts-2 parallax parallax-overlay">
                <div className="bg-parallax-overlay plotform" style={{backgroundColor: 'white'}}></div>
                <div className="container">
                    <div className="row">
                        <div data-aos='fade-up' data-aos-anchor-placement="center-bottom" className="col-md-12" style={{backgroundColor: '#F7F7F7'}}>
                            <div className="container"></div>
                            <div className="themesflat-spacer clearfix" data-desktop="102" data-mobi="60" data-smobi="60"></div>
                            <div className="themesflat-headings style-1 clearfix text-center color-white">
                                <h2 className="heading" style={{color: "black", marginTop: '-50px'}}>{t('Platform.title')}</h2>
                                <div className="sep clearfix"></div>
                                <p className="sub-heading " style={{color: "black"}}>{t('Platform.content')}</p>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop="76" data-mobi="60" data-smobi="60"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HomePlatform;