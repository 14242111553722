import React from "react";
// import HomeTestimonial from "./HomeTestimonial";
import HomeSlider from "./HomeSlider";
import HomeVideo from "./HomeVideo";
import HomeFacts from "./HomeFacts";
import HomeRequest from "./HomeRequest";
import HomeNews from "./HomeNews";
import HomePlatform from "./HomePlatform";
// import ServiceRowPartners from "../Service/ServiceRowPartners";
import TestimonialsModal from "./TestimonialsModal";
import "../../i18n";
import { useTranslation } from "react-i18next";
import Map from "../Map";

function Home(props) {
  const { t } = useTranslation();
  return (
    <div id="wrapper" className="animsition">
      <div id="page" className="clearfix">
        <div id="main-content" className="site-main clearfix">
          <div id="content-wrap">
            <div id="site-content" className="site-content clearfix">
              <div id="inner-content" className="inner-content-wrap">
                <div className="page-content">
                  <HomeSlider />
                  <HomePlatform />
                  <HomeVideo />
                  <HomeNews />
                  <HomeFacts />
                  <HomeRequest />
                  {/* <HomeTestimonial/> */}
                  <div className="row-promotion bg-accent">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="themesflat-spacer clearfix"
                            data-desktop="24"
                            data-mobi="40"
                            data-smobi="40"
                          ></div>
                          <div className="themesflat-action-box style-1 has-icon padding-left-110 padding-right-113">
                            <div className="inner">
                              <div className="heading-wrap">
                                <div className="text-wrap">
                                  <span className="icon ">
                                    <img
                                      style={{
                                        width: "40px",
                                        marginBottom: "15px",
                                      }}
                                      src="assets/icon/chat.svg"
                                    />
                                  </span>
                                  <h3 className="heading mobi-padding-top20 mobi-padding-bottom20">
                                    {t("PopUp.title")}
                                  </h3>
                                  <span className="icon">
                                    <i className="as-icon-speedometer2"></i>
                                  </span>
                                </div>
                              </div>
                              <div className="button-wrap">
                                <TestimonialsModal
                                  className="themesflat-button white font-weight-600 margin-top-10 margin-bottom-13"
                                  text={t("PopUp.button")}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="themesflat-spacer clearfix"
                            data-desktop="20"
                            data-mobi="40"
                            data-smobi="40"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row-maps">
                  <div className="container">
                    <div className="row clearfix">
                      <div className="col-md-12">
                        <div
                          className="themesflat-spacer clearfix"
                          data-desktop="94"
                          data-mobi="60"
                          data-smobi="60"
                        ></div>
                        <div
                          className="container"
                          style={{ height: "60vh", width: "90%" }}
                        >
                          <Map />
                        </div>
                        <div
                          className="themesflat-spacer clearfix"
                          data-desktop="94"
                          data-mobi="60"
                          data-smobi="60"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ServiceRowPartners/> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
