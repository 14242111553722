import React from "react";
import ServiceFeatured from "./ServiceFeatured";
import ServiceRowServices from "./ServiceRowServices";
import ServiceRowFacts1 from "./ServiceRowFacts1";
import ServiceRowServices2 from "./ServiceRowServices2";
import ServiceRowPartners from "./ServiceRowPartners";

const Service = () => {
    return (
        <div id="wrapper" className="animsition">
            <div id="page" className="clearfix">
                <div className="hero-section slideshow text-center"
                     data-height="840"
                     data-count="2"
                     data-image="assets/img/serviceholder.jpg"
                     data-effect="fade"
                     data-overlay=""
                     data-poverlay="">
                    <div className="hero-content">
                        <div className="themesflat-fancy-text typed" data-fancy="Services,Comunity,Analysis">
                            <h1 className="heading font-weight-700 letter-spacing--2">
                                FINACE <span className="text">SERVICES</span>
                            </h1>
                        </div>
                        <div className="themesflat-spacer clearfix"
                             data-desktop="21"
                             data-mobi="21"
                             data-smobi="21"></div>
                        <p className="sub-heading">We have years of experience helping clients find the best financing while meeting their financial goals.</p>
                        <div className="elm-btn">
                            <a href="/service" className="themesflat-button accent">REQUEST A CALL BACK</a>
                        </div>
                    </div>
                </div>
                <ServiceFeatured/>
                <div id="main-content" className="site-main clearfix">
                    <div id="content-wrap">
                        <div id="site-content" className="site-content clearfix">
                            <div id="inner-content" className="inner-content-wrap">
                                <div className="page-content">
                                    <ServiceRowServices/>
                                    <ServiceRowFacts1/>
                                    <ServiceRowServices2/>
                                    <ServiceRowPartners/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Service;