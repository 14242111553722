const ServiceRowServices2 = () => {
    return (
        <div className="row-services">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="themesflat-spacer clearfix" data-desktop="91" data-mobi="60" data-smobi="60"></div>
                        <div className="themesflat-headings style-1 clearfix text-center">
                            <h2 className="heading clearfix">OUR MAIN SERVICES</h2>
                            <div className="sep clearfix"></div>
                            <p className="sub-heading clearfix">Appropriate and sufficient financing for a company is crucially important for its long-term success. We advise you in choosing the appropriate type of financing</p>
                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop="52" data-mobi="40" data-smobi="40"></div>
                    </div>
                    <div className="col-md-4 border-right-1 dark padding-left-62 padding-right-45">
                        <div className="themesflat-icon-box accent-bg clearfix w93 text-center rounded-100 has-width">
                            <div className="icon-wrap">
                                <i className="finance-icon-bars-1"></i>
                            </div>
                            <h3 className="heading font-size-16 font-weight-600 margin-top-17 margin-bottom-10">
                                <a href="/">PROJECT FINANCE</a>
                            </h3>
                            <p className="desc">We aim at developing and implementing customized and sustainable project financing solutions together with our clients. Derivative instruments to hedge against interest rate and currency risks</p>
                            <div className="elm-btn">
                                <a target="_blank" className=" simple-link font-heading" href="/">READ MORE</a>
                            </div>
                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop="0" data-mobi="40" data-smobi="40"></div>
                    </div>
                    <div className="col-md-4 border-right-1 dark padding-left-50 padding-right-45">
                        <div className="themesflat-icon-box accent-bg clearfix w93 text-center rounded-100 has-width">
                            <div className="icon-wrap">
                                <i className="finance-icon-bank-2"></i>
                            </div>
                            <h3 className="heading font-size-16 font-weight-600 margin-top-17 margin-bottom-10">
                                <a href="/">INVESTMENT LOAN</a>
                            </h3>
                            <p className="desc">Loan business play a vital role in keeping your business competitive. In many cases, such measures and investments require capital and funding has to be supplemented by external financing.</p>
                            <div className="elm-btn">
                                <a target="_blank" className=" simple-link font-heading" href="/">READ MORE</a>
                            </div>
                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop="0" data-mobi="40" data-smobi="40"></div>
                    </div>
                    <div className="col-md-4 padding-left-54 padding-right-45">
                        <div className="themesflat-icon-box accent-bg clearfix w93 text-center rounded-100 has-width">
                            <div className="icon-wrap">
                                <i className="finance-icon-buildings-2"></i>
                            </div>
                            <h3 className="heading font-size-16 font-weight-600 margin-top-17 margin-bottom-10">
                                <a href="/">REAL ESTATE & PLANNING</a>
                            </h3>
                            <p className="desc">Finance focuses its services on a wide variety of financing solutions as well as for real estate project development and real estate projects. We are specialist for real estate financing and project development.</p>
                            <div className="elm-btn">
                                <a target="_blank" className=" simple-link font-heading" href="/">READ MORE</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="themesflat-spacer clearfix" data-desktop="96" data-mobi="60" data-smobi="60"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ServiceRowServices2;