import React from 'react';
import {API_IMG, API_URL, API_IMGBOOK} from "../../constants/variables";
import {useTranslation} from "react-i18next";
// import Books from '../DataInformation/Books/Books';

const PostsSearch = ({searchedArray, loading}) => {
    const {t} = useTranslation();
    const language = localStorage.getItem('lang');
    const handleSingle = (id) => {
        localStorage.setItem('newsId', id);
    }
    if(loading){
        return <h2>Loading...</h2>
    }
    return (
        <div>
         {searchedArray && searchedArray.map((item) =>
            <div key={item.createId} className="col-md-4" style={{marginBottom: '10vh'}}>
                <div className="news-item style-1 clearfix">
                    <div className="inner">
                        <div className="thumb">
                            <img style={{
                                height: '30vh',
                                width: "100%",
                                objectFit: 'cover'
                            }}
                                    src={API_IMG + item.image}
                                    alt="ImageNews"
                                    onClick={() => handleSingle(item.id)}/>
                            <h3 className="title">
                                <a href={item.kind === "news" ? "/newsSingle" : "/singleBook"} onClick={() => handleSingle(item.id)}>
                                    {language === 'uz' ? item.title_uz : item.title_ru}
                                </a>
                            </h3>
                        </div>
                        <div className="text-wrap" style={{height: '35vh'}}>
                            <p className="excerpt-text">{language === 'uz' ? item.description_uz : item.description_ru}</p>
                            <div className="post-btn">
                                <a href={item.kind === "news" ? "/newsSingle" : "/singleBook"}
                                   onClick={() => handleSingle(item.id)}
                                   className="simple-link">{t('News.read')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         )}
        </div>
    )
}
export default PostsSearch
