import { withRouter } from "react-router-dom";
import "./header.css";
import React, { useEffect, useState } from "react";
import i18 from "../../i18n";
import axios from "axios";
import { useTranslation } from "react-i18next";
// import {API_URLNEW} from "../../constants/variables";
import { API_URL } from "../../constants/variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const [searches, setSearches] = useState("");
  const [offset, setOffset] = useState(0);
  const [lang, setLang] = useState("");
  const [menu, setMenu] = useState([]);
  const [subMenu, setSubMenu] = useState([]);
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");
  const handlerChange = (e) => {
    e.preventDefault();
    localStorage.setItem("searches", searches);
    window.location.href = "/searchs";
    setSearches("");
  };
  const handleSearch = (e) => {
    setSearches(e.target.value);
  };
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  });
  useEffect(() => {
    if (language != undefined || language === "ru") {
      setLang(localStorage.getItem("lang"));
    } else {
      setLang("uz");
    }
  });
  useEffect(() => {
    setLang(localStorage.getItem("lang"));
  }, []);
  useEffect(() => {
    axios.get(API_URL + "/category").then((res) => {
      setMenu(res.data.category.filter((item) => item.parent_id == "0"));
      setSubMenu(res.data.category);
    });
  }, []);
  const handleLanguage = (e) => {
    window.location.reload(false);
    localStorage.setItem("lang", e.target.value);
    i18.changeLanguage(e.target.value);
    setLang(localStorage.getItem("lang"));
  };
  const getByCategory = (name) => {
    let res;
    if (language == "ru") {
      res = subMenu.find(
        (item) => item.name_ru.toLowerCase() === name.toLowerCase()
      );
    } else {
      res = subMenu.find(
        (item) => item.name_uz.toLowerCase() === name.toLowerCase()
      );
    }
    localStorage.setItem("categoryId", res.id);
  };
  return (
    <div>
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="site-header-wrap" className="absolute">
            <header id="site-header">
              <div
                style={{ width: "95%" }}
                className="wrap-themesflat-container"
              >
                <div id="site-header-inner" className="themesflat-container">
                  <div className="wrap-inner">
                    <div id="site-logo" className="clearfix logoPush">
                      <div id="site-logo-inner">
                        <a
                          href="/"
                          title="Finance"
                          rel="home"
                          className="main-logo"
                        >
                          <img
                            src={
                              offset > 1 ? `/logoBlue.png` : `/logoWhite.png`
                            }
                            width="184"
                            height="40"
                            alt="Finance"
                            data-width="184"
                            data-height="40"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="mobile-button">
                      <span> </span>
                    </div>
                    <nav id="main-nav" className="main-nav">
                      <ul id="menu-primary-menu" className="menu">
                        <li className="menu-item  menu-item-has-children text-uppercase">
                          <div className="poisk">
                            <form
                              onSubmit={handlerChange}
                              style={{
                                width: "100%",
                                position: "relative",
                              }}
                            >
                              <input
                                onChange={handleSearch}
                                value={searches}
                                style={{
                                  color: "#fff",
                                  height: "40px",
                                  width: "95%",
                                }}
                                type="search"
                                placeholder={t("SediBar.search")}
                                name="s"
                                title="Search for:"
                              />
                              <a
                                onClick={handlerChange}
                                style={{
                                  position: "absolute",
                                  top: "2px",
                                  right: "25px",
                                  borderLeft: "2px solid #fff",
                                  paddingLeft: "13px",
                                  height: "37px",
                                  paddingTop: "5px",
                                }}
                                type="submit"
                                title="Search"
                              >
                                {" "}
                                <FontAwesomeIcon icon={faSearch} />
                              </a>
                            </form>
                          </div>
                        </li>
                        <li className="menu-item  menu-item-has-children text-uppercase">
                          <a href="/stateandfamily">{t("Menu.menu1")}</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a
                                href="/facility"
                                onClick={() => getByCategory(t("Menu.menu2"))}
                              >
                                {t("Menu.menu2")}
                              </a>
                            </li>
                            <li className="menu-item">
                              <a
                                href="/credit"
                                onClick={() => getByCategory(t("Menu.menu3"))}
                              >
                                {t("Menu.menu3")}
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-has-children text-uppercase">
                          <a href="/babyBring">{t("Menu.menu4")}</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu5"))}
                                href="/yearToOne"
                              >
                                {t("Menu.menu5")}
                              </a>
                            </li>
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu6"))}
                                href="/yearToFive"
                              >
                                {t("Menu.menu6")}
                              </a>
                            </li>
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu7"))}
                                href="/yearToFourteen"
                              >
                                {t("Menu.menu7")}
                              </a>
                            </li>
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu8"))}
                                href="/yearToEighteen"
                              >
                                {t("Menu.menu8")}
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-has-children text-uppercase">
                          <a href="/advice">{t("Menu.menu9")}</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu10"))}
                                href="/marriage"
                              >
                                {t("Menu.menu10")}
                              </a>
                            </li>
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu11"))}
                                href="/law"
                              >
                                {t("Menu.menu11")}
                              </a>
                            </li>
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu12"))}
                                href="/religion"
                              >
                                {t("Menu.menu12")}
                              </a>
                            </li>
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu13"))}
                                href="/psychology"
                              >
                                {t("Menu.menu13")}
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item  menu-item-has-children text-uppercase">
                          <a href="/health">{t("Menu.menu14")}</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu15"))}
                                href="/medical"
                              >
                                {t("Menu.menu15")}
                              </a>
                            </li>
                            <li className="menu-item menu-item-has-children">
                              <a href="/periodPregnancy">{t("Menu.menu16")}</a>
                              <ul className="sub-menu">
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu17"))
                                    }
                                    href="/trimestrOne"
                                  >
                                    {t("Menu.menu17")}
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu18"))
                                    }
                                    href="/trimestrTwo"
                                  >
                                    {t("Menu.menu18")}
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu19"))
                                    }
                                    href="/trimestrThree"
                                  >
                                    {t("Menu.menu19")}
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="menu-item  menu-item-has-children">
                              <a href="/baby">{t("Menu.menu20")}</a>
                              <ul className="sub-menu">
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu21"))
                                    }
                                    href="/milkBaby"
                                  >
                                    {t("Menu.menu21")}
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu22"))
                                    }
                                    href="/youngPupil"
                                  >
                                    {t("Menu.menu22")}
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu23"))
                                    }
                                    href="/teenagePeriod"
                                  >
                                    {t("Menu.menu23")}
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="menu-item  menu-item-has-children">
                              <a href="/healthLife">{t("Menu.menu24")}</a>
                              <ul className="sub-menu">
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu25"))
                                    }
                                    href="/virus"
                                  >
                                    {t("Menu.menu25")}
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu26"))
                                    }
                                    href="/clinic"
                                  >
                                    {t("Menu.menu26")}
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu27"))
                                    }
                                    href="/oldMen"
                                  >
                                    {t("Menu.menu27")}
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu28"))
                                    }
                                    href="/higienic"
                                  >
                                    {t("Menu.menu28")}
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu29"))
                                    }
                                    href="/diseas"
                                  >
                                    {t("Menu.menu29")}
                                  </a>
                                </li>
                                <li className="menu-item">
                                  <a
                                    onClick={() =>
                                      getByCategory(t("Menu.menu30"))
                                    }
                                    href="/improperNutriton"
                                  >
                                    {t("Menu.menu30")}
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item menu-item-has-children text-uppercase">
                          <a href="/dataInfonmation">{t("Menu.menu31")}</a>
                          <ul className="sub-menu">
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu35"))}
                                href="/video"
                              >
                                {t("Menu.menu35")}
                              </a>
                            </li>
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu36"))}
                                href="/books"
                              >
                                {t("Menu.menu36")}
                              </a>
                            </li>
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu37"))}
                                href="/news"
                              >
                                {t("Menu.menu37")}
                              </a>
                            </li>
                            <li className="menu-item">
                              <a
                                onClick={() => getByCategory(t("Menu.menu38"))}
                                href="/foto"
                              >
                                {t("Menu.menu38")}
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li
                          className="menu-item  menu-item-has-children text-uppercase"
                          style={{ width: "80px" }}
                        >
                          {/*<select className="langSelect" style={{border: 0, color: 'white', width:'80px', margin:"0", paddingTop:'35px'}}*/}
                          {/*        value={lang && lang}*/}
                          {/*        onChange={handleLanguage}>*/}
                          {/*    <option key={1} value="ru" style={{color: '#FFF', background:'#333'}}>Ru</option>*/}
                          {/*    <option key={2} value="uz" style={{color: '#FFF', background:'#333'}}>Uz</option>*/}
                          {/*</select>*/}
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Header);
