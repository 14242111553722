import React, { useEffect } from "react";
import Home from "./component/Home/Home";
import Header from "./component/Header/Header";
import { Route, Switch } from "react-router-dom";
import { UserContext } from "./util/UserContext";
import Social from "./component/Social";
import About from "./component/About";
import Contact from "./component/Contact/Contact";
import Service from "./component/Service/Service";
import Portfolio from "./component/Portfolio/Portfolio";
import Project1 from "./component/Portfolio/Project1";
import Project2 from "./component/Portfolio/Project2";
import News from "./component/News/News";
import NewsSingle from "./component/NewsSingle/NewsSingle";
import YearToOne from "./component/Baby/YearToOne/YearToOne";
import YearToFive from "./component/Baby/YearToFive/YearToFive";
import YearToFourteen from "./component/Baby/YearToFourteen/YearToFourteen";
import YearToEighteen from "./component/Baby/YearToEighteen/YearToEighteen";
import Religion from "./component/Advice/Religion/Religion";
import Psychology from "./component/Advice/Psychology/Psychology";
import Medical from "./component/Health/Medical/Medical";
import TrimestrOne from "./component/Health/Trimestr/TrimestrOne";
import TrimestrTwo from "./component/Health/Trimestr/TrimestrTwo";
import TrimestrThree from "./component/Health/Trimestr/TrimestrThree";
import Baby from "./component/Health/Baby";
import MilkBaby from "./component/Health/Baby/MilkBaby/MilkBaby";
import YoungPupil from "./component/Health/Baby/YoungPupil/YoungPupil";
import TeenagePeriod from "./component/Health/Baby/TeenagePeriod";
import Advise from "./component/Advice/Advise";
import Facility from "./component/StateAndFamily/Lgoti/Lgoti";
import Credit from "./component/StateAndFamily/Credit/Kredit";
import Law from "./component/Advice/Law/Law";
import PeriodPregnancy from "./component/Health/Baby/PeriodPregnancy";
import Marriage from "./component/Advice/Marriage/Marriage";
import HealthLife from "./component/Health/LifeStyle/HealthLife";
import Virus from "./component/Health/LifeStyle/Virus/Virus";
import Clinic from "./component/Health/LifeStyle/Clinic/Clinic";
import OldMen from "./component/Health/LifeStyle/OldMen/OldMen";
import Higienic from "./component/Health/LifeStyle/Gigiena/Higienic";
import Diseas from "./component/Health/LifeStyle/Diseas";
import ImproperNutrition from "./component/Health/LifeStyle/ImproperNutrition";
import Footer from "./component/Footer/Footer";
import StateAndFamily from "./component/StateAndFamily/StateAndFamily";
import BabyBring from "./component/Baby/BabyBring";
import Health from "./component/Health/Health";
import DataInformation from "./component/DataInformation/DataInformation";
import Video from "./component/DataInformation/Video/Video";
import Books from "./component/DataInformation/Books/Books";
import SingleBook from "./component/DataInformation/Books/SingleBook";
import Foto from "./component/DataInformation/Foto/Foto";
import FotoGalery from "./component/DataInformation/Foto/FotoGalery";
import SingleFoto from "./component/DataInformation/Foto/SingleFoto";
import Tegs from "./component/Tegs/Tegs";
import Search from "./component/Header/Search";
import SingleMilkBaby from "./component/Health/Baby/MilkBaby/SingleMilkBaby";
import SingleMedical from "./component/Health/Medical/SingleMedical";

function App() {
  useEffect(() => {
    const language = localStorage.getItem("lang");
    if (language === undefined) {
      localStorage.setItem("lang", "uz");
    }
  });
  return (
    <div>
      <UserContext.Provider>
        <Header />
        <Social />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/service" component={Service} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/project1" component={Project1} />
          <Route exact path="/project2" component={Project2} />
          <Route exact path="/news" component={News} />
          <Route exact path="/newsSingle" component={NewsSingle} />
          <Route exact path="/yearToOne" component={YearToOne} />
          <Route exact path="/yearToFive" component={YearToFive} />
          <Route exact path="/yearToFourteen" component={YearToFourteen} />
          <Route exact path="/yearToEighteen" component={YearToEighteen} />
          <Route exact path="/religion" component={Religion} />
          <Route exact path="/psychology" component={Psychology} />
          <Route exact path="/medical" component={Medical} />
          <Route exact path="/trimestrOne" component={TrimestrOne} />
          <Route exact path="/trimestrTwo" component={TrimestrTwo} />
          <Route exact path="/trimestrThree" component={TrimestrThree} />
          <Route exact path="/baby" component={Baby} />
          <Route exact path="/milkBaby" component={MilkBaby} />
          <Route exact path="/youngPupil" component={YoungPupil} />
          <Route exact path="/teenagePeriod" component={TeenagePeriod} />
          <Route exact path="/advice" component={Advise} />
          <Route exact path="/facility" component={Facility} />
          <Route exact path="/credit" component={Credit} />
          <Route exact path="/law" component={Law} />
          <Route exact path="/periodPregnancy" component={PeriodPregnancy} />
          <Route exact path="/marriage" component={Marriage} />
          <Route exact path="/healthLife" component={HealthLife} />
          <Route exact path="/virus" component={Virus} />
          <Route exact path="/clinic" component={Clinic} />
          <Route exact path="/oldMen" component={OldMen} />
          <Route exact path="/higienic" component={Higienic} />
          <Route exact path="/diseas" component={Diseas} />
          <Route exact path="/improperNutriton" component={ImproperNutrition} />
          <Route exact path="/stateandfamily" component={StateAndFamily} />
          <Route exact path="/babyBring" component={BabyBring} />
          <Route exact path="/health" component={Health} />
          <Route exact path="/dataInfonmation" component={DataInformation} />
          <Route exact path="/video" component={Video} />
          <Route exact path="/books" component={Books} />
          <Route exact path="/singleBook" component={SingleBook} />
          <Route exact path="/foto" component={Foto} />
          <Route exact path="/fotoGalery" component={FotoGalery} />
          <Route exact path="/singleFoto" component={SingleFoto} />
          <Route exact path="/tegs" component={Tegs} />
          <Route exact path="/searchs" component={Search} />
          <Route exact path="/singleMilkBaby" component={SingleMilkBaby} />
          <Route exact path="/singleMedical" component={SingleMedical} />
          <Route exact path="*">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </UserContext.Provider>
    </div>
  );
}

export default App;
