import {useTranslation} from "react-i18next";

export default function FooterFotoPotok() {
    const {t} = useTranslation();
    return (
        <div className="span_1_of_4 col">
            <div className="widget widget_flickr margin-top-6 padding-left-6">
                <h2 className="widget-title">
                    <span>{t('Footer.foto')}</span>
                </h2>
                <div className="flickr-wrap clearfix col3 g12">
                    <div className="flickr_badge_image">
                        <a href="https://instagram.com/yoshoila_uz?igshid=gcz4zns6qck" target="_blank">
                            <div className="item">
                                <img className="img" src="assets/img/video/footer1.webp" alt="ImageFooter" style={{height: "80px", width: "85px"}}/>
                            </div>
                        </a>
                    </div>
                    <div className="flickr_badge_image">
                        <a href="https://instagram.com/yoshoila_uz?igshid=gcz4zns6qck" target="_blank">
                            <div className="item">
                                <img className="img" src="assets/img/video/footer3.webp" alt="ImageFooter" style={{height: "80px", width: "85px"}}/>
                            </div>
                        </a>
                    </div>
                    <div className="flickr_badge_image">
                        <a href="https://instagram.com/yoshoila_uz?igshid=gcz4zns6qck" target="_blank">
                            <div className="item">
                                <img className="img" src="assets/img/video/footer4.webp" alt="ImageFooter" style={{height: "80px", width: "85px"}}/>
                            </div>
                        </a>
                    </div>
                    <div className="flickr_badge_image">
                        <a href="https://instagram.com/yoshoila_uz?igshid=gcz4zns6qck" target="_blank">
                            <div className="item">
                                <img className="img" src="assets/img/video/footer5.webp" alt="ImageFooter" style={{height: "80px", width: "85px"}}/>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}