import React, { useEffect, useState } from "react";
import HomeRequest from "../../Home/HomeRequest";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "../../../constants/variables";

function PeriodPregnancy() {
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");
  const [subMenu, setSubMenu] = useState([]);

  useEffect(() => {
    axios.get(API_URL + "/category").then((res) => {
      setSubMenu(res.data.category);
    });
  }, []);

  const getByCategory = (name) => {
    console.log(name);
    let res;
    if (language == "ru") {
      res = subMenu.find(
        (item) => item.name_ru.toLowerCase() === name.toLowerCase()
      );
    } else {
      res = subMenu.find(
        (item) => item.name_uz.toLowerCase() === name.toLowerCase()
      );
      // localStorage.setItem('categoryId', result.id);
    }
    localStorage.setItem("categoryId", res.id);
  };
  return (
    <div>
      <div id="main-content" className="site-main clearfix">
        <div id="content-wrap">
          <div id="site-content" className="site-content clearfix">
            <div id="inner-content" className="inner-content-wrap">
              <div
                className="hero-section slideshow text-center"
                data-height="840"
                data-count="2"
                data-image="./assets/img/health/pregnancy1.webp"
                data-effect="fade"
                data-overlay=""
                data-poverlay=""
              >
                <div className="hero-content">
                  <div
                    className="themesflat-fancy-text typed"
                    data-fancy={t("Pragrancy.slider2")}
                  >
                    <h1
                      style={{ background: "transparent" }}
                      className="heading font-weight-700 letter-spacing--2"
                    >
                      {t("Pragrancy.slider1")}
                      <span className="text">{t("Pragrancy.slider2")}</span>
                    </h1>
                  </div>

                  <div
                    className="themesflat-spacer clearfix"
                    data-desktop="21"
                    data-mobi="21"
                    data-smobi="21"
                  ></div>
                  <div className="elm-btn">
                    <a href="#request" className="themesflat-button accent">
                      {t("Slider.button")}
                    </a>
                  </div>
                </div>
                <a className="arrow scroll-target" href="#content"></a>
              </div>
              <div id="content" className="row-services">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="themesflat-spacer clearfix"
                        data-desktop="91"
                        data-mobi="60"
                        data-smobi="60"
                      ></div>

                      <div className="themesflat-headings style-1 clearfix text-center">
                        <h2 className="heading clearfix">
                          {t("Pragrancy.title")}
                        </h2>
                        <div className="sep clearfix"></div>
                        <p className="sub-heading clearfix">
                          {t("Pragrancy.content")}
                        </p>
                      </div>

                      <div
                        className="themesflat-spacer clearfix"
                        data-desktop="52"
                        data-mobi="40"
                        data-smobi="40"
                      ></div>
                    </div>

                    <div className="col-md-4 border-right-1 dark padding-left-62 padding-right-45">
                      <div className="themesflat-icon-box accent-bg clearfix w93 text-center rounded-100 has-width">
                        <a
                          style={{ height: "60px", width: "60px" }}
                          href="/trimestrOne"
                        >
                          <div className="icon-wrap">
                            <i>I</i>
                          </div>
                        </a>

                        <h3 className="heading font-size-16 font-weight-600 margin-top-17 margin-bottom-10">
                          <a
                            onClick={() => getByCategory(t("Menu.menu17"))}
                            href="/trimestrOne"
                          >
                            {t("Menu.menu17")}
                          </a>
                        </h3>

                        <p className="desc">{t("Pragrancy.content1")}</p>

                        <div className="elm-btn">
                          <a
                            className=" simple-link font-heading"
                            onClick={() => getByCategory(t("Menu.menu17"))}
                            href="/trimestrOne"
                          >
                            {t("News.read")}
                          </a>
                        </div>
                      </div>

                      <div
                        className="themesflat-spacer clearfix"
                        data-desktop="0"
                        data-mobi="40"
                        data-smobi="40"
                      ></div>
                    </div>
                    <div className="col-md-4 border-right-1 dark padding-left-50 padding-right-45">
                      <div className="themesflat-icon-box accent-bg clearfix w93 text-center rounded-100 has-width">
                        <a
                          style={{ height: "60px", width: "60px" }}
                          href="/trimestrTwo"
                        >
                          <div className="icon-wrap">
                            <i>II</i>
                          </div>
                        </a>

                        <h3 className="heading font-size-16 font-weight-600 margin-top-17 margin-bottom-10">
                          <a
                            onClick={() => getByCategory(t("Menu.menu18"))}
                            href="/trimestrTwo"
                          >
                            {t("Menu.menu18")}
                          </a>
                        </h3>

                        <p className="desc">{t("Pragrancy.content2")}</p>

                        <div className="elm-btn">
                          <a
                            className=" simple-link font-heading"
                            onClick={() => getByCategory(t("Menu.menu18"))}
                            href="/trimestrTwo"
                          >
                            {t("News.read")}
                          </a>
                        </div>
                      </div>

                      <div
                        className="themesflat-spacer clearfix"
                        data-desktop="0"
                        data-mobi="40"
                        data-smobi="40"
                      ></div>
                    </div>

                    <div className="col-md-4 padding-left-54 padding-right-45">
                      <div className="themesflat-icon-box accent-bg clearfix w93 text-center rounded-100 has-width">
                        <a
                          style={{ height: "60px", width: "60px" }}
                          href="/trimestrTwo"
                        >
                          <div className="icon-wrap">
                            <i>III</i>
                          </div>
                        </a>

                        <h3 className="heading font-size-16 font-weight-600 margin-top-17 margin-bottom-10">
                          <a
                            onClick={() => getByCategory(t("Menu.menu19"))}
                            href="/trimestrThree"
                          >
                            {t("Menu.menu19")}
                          </a>
                        </h3>

                        <p className="desc">{t("Pragrancy.content3")}</p>

                        <div className="elm-btn">
                          <a
                            className=" simple-link font-heading"
                            onClick={() => getByCategory(t("Menu.menu19"))}
                            href="/trimestrThree"
                          >
                            {t("News.read")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <HomeRequest id="request" />
              <div className="col-md-12">
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop="96"
                  data-mobi="60"
                  data-smobi="60"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PeriodPregnancy;
