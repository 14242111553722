import React, {useState} from "react";

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const write = (e) => {
        console.log(name)
        console.log(email)
    }

    const changeName = (e) => {
        setName(e.target.value)
    }

    return (
        <div>
            <div style={{height: '100px', backgroundColor: 'rgba(0, 0, 0, 0.7)'}}> </div>
            <div id="featured-title" className="featured-title clearfix">
                <div id="featured-title-inner" className="themesflat-container clearfix">
                    <div className="featured-title-inner-wrap">
                        <div className="featured-title-heading-wrap">
                            <h1 className="featured-title-heading">
                                КОНТАКТЫ
                            </h1>
                        </div>
                        <div id="breadcrumbs">
                            <div className="breadcrumbs-inner">
                                <div className="breadcrumb-trail">
                                    <a href="home.html" className="trail-begin">Home</a>
                                    <span className="sep">
                                        <i className="finance-icon-chevron-right"> </i>
                                    </span>
                                    <span className="trail-end">КОНТАКТЫ</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="main-content" className="site-main clearfix">
                <div id="content-wrap">
                    <div id="site-content" className="site-content clearfix">
                        <div id="inner-content" className="inner-content-wrap">
                            <div className="page-content">
                                <div className="row-maps">
                                    <div className="container">
                                        <div className="row clearfix">
                                            <div className="col-md-12">
                                                <div className="themesflat-spacer clearfix"
                                                     data-desktop="94"
                                                     data-mobi="60"
                                                     data-smobi="60">
                                                </div>
                                                <div className="themesflat-gmap style-1"
                                                     data-lat="41.320151"
                                                     data-lng="69.262558"
                                                     data-marker="assets/img/marker.png"
                                                     data-zoom="14">
                                                </div>
                                                <div className="themesflat-spacer clearfix"
                                                     data-desktop="94"
                                                     data-mobi="60"
                                                     data-smobi="60">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row-contact bg-light-grey">
                                    <div className="container">
                                        <div className="row clearfix">
                                            <div className="col-md-12">
                                                <div className="themesflat-spacer clearfix"
                                                     data-desktop="90"
                                                     data-mobi="60"
                                                     data-smobi="60">
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="themesflat-contact-form style-1">
                                                        <form onSubmit={write} className="contact-form wpcf7-form">
                                                            <span className="wpcf7-form-control-wrap your-name">
                                                                <input type="text" tabIndex="1" id="name" name="name"
                                                                       className="wpcf7-form-control"
                                                                       onChange={changeName}
                                                                       placeholder="Имя*" required/>
                                                            </span>
                                                            <span className="wpcf7-form-control-wrap your-phone">
                                                                <input type="text" tabIndex="2" id="phone" name="phone"
                                                                       className="wpcf7-form-control"
                                                                       onChange={(e) => setEmail(e.target.value)}
                                                                       placeholder="Ваш номер *" required/>
                                                            </span>
                                                            <span className="wpcf7-form-control-wrap your-select">
                                                                <select name="time">
                                                                    <option value="">Удобное для вас время</option>
                                                                    <option value="">Удобное для вас время</option>
                                                                    <option value="">Удобное для вас время</option>
                                                                </select>
                                                            </span>
                                                            <span className="wpcf7-form-control-wrap your-subject">
                                                                <input type="text" tabIndex="3" id="subject"
                                                                       name="subject"
                                                                       className="wpcf7-form-control"
                                                                       placeholder="Тема*"
                                                                       required/>
                                                            </span>
                                                            <span className="wpcf7-form-control-wrap your-message">
                                                                <textarea name="message" tabIndex="4" cols="40"
                                                                          rows="10"
                                                                          className="wpcf7-form-control wpcf7-textarea"
                                                                          placeholder="Ваше сообщение*"
                                                                          required>
                                                                </textarea>
                                                            </span>
                                                            <div className="wrap-submit">
                                                                <input type="submit" style={{color: 'white'}}
                                                                       value="ОТПРАВИТЬ"
                                                                       className="submit wpcf7-form-control wpcf7-submit"
                                                                       id="submit" name="submit"/>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className=" col-md-3">
                                                    <div className=" themesflat-spacer clearfix"
                                                         data-desktop="0"
                                                         data-mobi="30"
                                                         data-smobi="30">
                                                    </div>
                                                    <div className="themesflat-content-box clearfix"
                                                         data-margin="2px 0px 0px 9px"
                                                         data-mobimargin="0px 0px 0px 0px">
                                                        <div className="themesflat-contact-info">
                                                            <div className="inner">
                                                                <div className="contact-info-item clearfix">
                                                                    <h6 className="title">
                                                                        <span>Телефонный номер</span>
                                                                    </h6>
                                                                    <ul>
                                                                        <li>
                                                                            <a href="/">1093</a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="contact-info-item clearfix">
                                                                    <h6 className="title margin-bottom-16">
                                                                        <span>АДРЕС</span>
                                                                    </h6>
                                                                    <p>Toshkent 100011, Alisher Navoiy ko'chasi 11-uy</p>
                                                                    <div className="socials clearfix">
                                                                        <span className="icons">
                                                                            <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Ffb.me%2Fyoshoilaplatformasi&h=AT15Wep5lDjDAoy3WrcFSbKDnhlW8C6822dzqUHG0QCaBIvskehM2idAr4v91KzVAzV3fF7boBg7264fH5MOlymduo5RJ7ObFaXMd43ac36Def7HtDKWYRpwp1PoGGrZ0bgYdw"
                                                                               title="Facebook"
                                                                               target="_blank">
                                                                             <span className="iconify"
                                                                                   data-icon="brandico:facebook-rect"
                                                                                   data-inline="false">
                                                                             </span>
                                                                            </a>
                                                                            <a href="/contact"
                                                                               title="Twitter"
                                                                               target="_blank">
                                                                               <span className="iconify"
                                                                                     data-icon="simple-icons:tiktok"
                                                                                     data-inline="false">
                                                                               </span>
                                                                            </a>
                                                                            <a href="https://instagram.com/yoshoila_uz?igshid=gcz4zns6qck"
                                                                               title="Instagram"
                                                                               target="_blank">
                                                                                  <span className="iconify"
                                                                                        data-icon="topcoat:instagram"
                                                                                        data-inline="false">
                                                                                  </span>
                                                                            </a>
                                                                            <a href="https://www.youtube.com/channel/UCfWPwvda7JSbk7hMBcGg0Xg"
                                                                               title="Twitter"
                                                                               target="_blank">
                                                                                  <span className="iconify"
                                                                                        data-icon="ion:logo-youtube"
                                                                                        data-inline="false">
                                                                                  </span>
                                                                            </a>
                                                                            <a href="/contact"
                                                                               title="Twitter"
                                                                               target="_blank">
                                                                                    <span className="iconify"
                                                                                          data-icon="jam:telegram"
                                                                                          data-inline="false">
                                                                                    </span>
                                                                            </a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="themesflat-spacer clearfix"
                                                     data-desktop="90"
                                                     data-mobi="60"
                                                     data-smobi="60">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;