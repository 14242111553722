import React, { useEffect, useState } from 'react';
import HomeRequest from "../Home/HomeRequest";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "../../constants/variables";
import ImgIconBaby from "../../image/baby/baby.svg";
import ImgIconLittlekid from "../../image/baby/littlekid.svg";
import ImgIconMale from "../../image/baby/male.svg";
import ImgIconSadbabyface from "../../image/baby/sad-baby-face.svg";

function BabyBring(props) {
    const { t } = useTranslation();
    const language = localStorage.getItem('lang')
    const [subMenu, setSubMenu] = useState([]);

    useEffect(() => {
        axios.get(API_URL + "/category").then(res => {
            setSubMenu(res.data.category);
        })
    }, [])

    const getByCategory = (name) => {
        console.log(name)
        let res;
        if (language === 'ru') {
            res = subMenu.find(item => item.name_ru.toLowerCase() === name.toLowerCase())
        } else {
            res = subMenu.find(item => item.name_uz.toLowerCase() === name.toLowerCase())
            // localStorage.setItem('categoryId', result.id);
        }
        localStorage.setItem('categoryId', res.id);
    }

    return (
        <div>
            <div id="main-content" className="site-main clearfix">
                <div id="content-wrap">
                    <div id="site-content" className="site-content clearfix">
                        <div id="inner-content" className="inner-content-wrap">
                            <div className="hero-section slideshow text-center" data-height="840" data-count="2"
                                data-image="./assets/img/bolalar/babyBring.webp" data-effect="fade" data-overlay=""
                                data-poverlay="">
                                <div className="hero-content">
                                    <div className="themesflat-fancy-text typed" data-fancy={t('Baby.slider2')}>
                                        <h1 style={{background:'transparent'}} className="heading font-weight-700 letter-spacing--2">
                                            {t('Baby.slider1')}<span className="text"> {t('Baby.slider2')}</span>
                                        </h1>
                                    </div>
                                    <div className="themesflat-spacer clearfix" data-desktop="21" data-mobi="21" data-smobi="21"> </div>
                                    <div className="elm-btn">
                                        <a href="#request" className="themesflat-button accent">{t('Slider.button')}</a>
                                    </div>
                                </div>
                                <a className="arrow scroll-target" href="#content"> </a>
                            </div>
                            <div id="content" className="row-services">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="themesflat-spacer clearfix" data-desktop="91" data-mobi="60" data-smobi="60"> </div>
                                            <div className="themesflat-headings style-1 clearfix text-center">
                                                <h2 className="heading clearfix">{t('Baby.title')}</h2>
                                                <div className="sep clearfix"> </div>
                                                <p className="sub-heading clearfix">{t('Baby.content')}</p>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop="52" data-mobi="40" data-smobi="40"> </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 border-bottom-1 dark">
                                                <div className="themesflat-icon-box style-1 clearfix w70 text-center rounded-100 has-width padding-left-36 padding-right-23">
                                                    <a style={{ height: '60px', width: '60px' }} href="/yearToOne">
                                                        <div className="icon-wrap">
                                                            <i>
                                                                <img src={ImgIconBaby} alt="albom" />
                                                            </i>
                                                        </div>
                                                    </a>
                                                    <h3 className="heading">
                                                        <a onClick={() => getByCategory(t('Menu.menu5'))} href="/yearToOne">
                                                            {t('Menu.menu5')}
                                                        </a>
                                                    </h3>
                                                    <p className="desc">{t('Baby.content1')}</p>
                                                </div>
                                                <div className="themesflat-spacer clearfix" data-desktop="36" data-mobi="40" data-smobi="40"> </div>
                                            </div>
                                            <div className="col-md-6 border-left-1 border-right-1 border-bottom-1 dark">
                                                <div className="themesflat-icon-box style-1 clearfix w70 text-center rounded-100 has-width padding-left-36 padding-right-30">
                                                    <a style={{ height: '60px', width: '60px' }} href="/yearToFive">
                                                    <div className="icon-wrap">
                                                        <i>
                                                            <img src={ImgIconSadbabyface} alt="albom"/>
                                                        </i>
                                                    </div>
                                                    </a>
                                                    <h3 className="heading">
                                                        <a onClick={() => getByCategory(t('Menu.menu6'))} href="/yearToFive">
                                                            {t('Menu.menu6')}
                                                        </a>
                                                    </h3>
                                                    <p className="desc">{t('Baby.content2')}</p>
                                                </div>
                                                <div className="themesflat-spacer clearfix" data-desktop="36" data-mobi="40" data-smobi="40"> </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="themesflat-spacer clearfix" data-desktop="32" data-mobi="40" data-smobi="40"> </div>
                                                <div className="themesflat-icon-box style-1 clearfix w70 text-center rounded-100 has-width padding-left-36 padding-right-23">
                                                    <a style={{ height: '60px', width: '60px' }} href="/yearToFourteen">
                                                    <div className="icon-wrap">
                                                        <i>
                                                            <img src={ImgIconLittlekid} alt="albom"/>
                                                        </i>
                                                    </div>
                                                    </a>
                                                    <h3 className="heading">
                                                        <a onClick={() => getByCategory(t('Menu.menu7'))} href="/yearToFourteen">
                                                            {t('Menu.menu7')}
                                                        </a>
                                                    </h3>
                                                    <p className="desc">{t('Baby.content3')}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-left-1 border-right-1 dark">
                                                <div className="themesflat-spacer clearfix" data-desktop="32" data-mobi="40" data-smobi="40"> </div>
                                                <div className="themesflat-icon-box style-1 clearfix w70 text-center rounded-100 has-width padding-left-36 padding-right-30">
                                                    <a style={{ height: '60px', width: '60px' }} href="/yearToEighteen">
                                                    <div className="icon-wrap">
                                                        <i>
                                                            <img src={ImgIconMale} alt="albom"/>
                                                        </i>
                                                    </div>
                                                    </a>
                                                    <h3 className="heading">
                                                        <a onClick={() => getByCategory(t('Menu.menu8'))} href="/yearToEighteen">
                                                            {t('Menu.menu8')}
                                                        </a>
                                                    </h3>
                                                    <p className="desc">{t('Baby.content4')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <HomeRequest id="request" />
                            <div className="col-md-12">
                                <div className="themesflat-spacer clearfix" data-desktop="96" data-mobi="60" data-smobi="60"> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BabyBring;