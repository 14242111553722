import React from "react";

function Social() {
  return (
    <div className="affix-layout">
      <div className="affix-content">
        <button className="affix-content-button4 affix-p-link">
          <a
            href="https://instagram.com/yoshoila_uz?igshid=gcz4zns6qck"
            target="_blank"
          >
            <img src="assets/img/social/instagram.webp" alt="albom" />
          </a>
        </button>
        <button className="affix-content-button affix-p-link">
          <a href="https://vm.tiktok.com/ZSep4XNRW/" target="_blank">
            <img src="assets/img/social/tiktok.webp" alt="albom" />
          </a>
        </button>
        {/*ssas*/}
        <button className="affix-content-button1 affix-p-link">
          <a
            href="https://www.youtube.com/channel/UCfWPwvda7JSbk7hMBcGg0Xg"
            target="_blank"
          >
            <img src="assets/img/social/youtube.webp" alt="albom" />
          </a>
        </button>
        <button className="affix-content-button2 affix-p-link">
          <a
            target="_blank"
            href="https://l.facebook.com/l.php?u=https%3A%2F%2Ffb.me%2Fyoshoilaplatformasi&h=AT15Wep5lDjDAoy3WrcFSbKDnhlW8C6822dzqUHG0QCaBIvskehM2idAr4v91KzVAzV3fF7boBg7264fH5MOlymduo5RJ7ObFaXMd43ac36Def7HtDKWYRpwp1PoGGrZ0bgYdw"
          >
            <img src="assets/img/social/facebook.webp" alt="albom" />
          </a>
        </button>
        <button className="affix-content-button3 affix-p-link">
          <a target="_blank" href="https://t.me/yoshoila_uz">
            <img src="assets/img/social/telegram.webp" alt="albom" />
          </a>
        </button>
      </div>
    </div>
  );
}

export default Social;
