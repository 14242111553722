import React from 'react';
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from "react-google-maps";

function GoogleMaps(props) {
    return (
        <div>
            <GoogleMap
                defaultZoom={12}
                defaultCenter={{lat: 41.320151, lng: 69.262558}}
            >
                <Marker
                    key={1}
                    position={{
                        lat: 41.320151,
                        lng: 69.262558
                    }}

                />
            </GoogleMap>
        </div>
    );
}

const WrappedMap = withScriptjs(withGoogleMap(GoogleMaps))

function Map() {
    return (
        <div style={{width: '100%', height: '50vh'}}>
            <WrappedMap
                googleMapURL={`<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.8234590407237!2d69.19068331566935!3d41.33445210723757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8c02058c4561%3A0xa0ac709aad17d85d!2zU2hheWtob250b2h1ciBEaXN0cmljdCwg7YOA7IqI7LyE7Yq4IOyasOymiOuyoO2CpOyKpO2DhA!5e0!3m2!1sko!2s!4v1622870199860!5m2!1sko!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`}
                loadingElement={<div style={{height: `100%`}}/>}
                containerElement={<div style={{height: `400px`}}/>}
                mapElement={<div style={{height: `100%`}}/>}
            />
        </div>
    );
}

export default Map;




