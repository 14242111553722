const NewsSingleFeaturedTitle = (props) => {
  return (
    <div id="featured-title" className="featured-title clearfix">
      <div id="featured-title-inner" className="themesflat-container clearfix">
        <div className="featured-title-inner-wrap">
          <div className="featured-title-heading-wrap">
            <h1 className="featured-title-heading">{props.pageName}</h1>
          </div>
          <div id="breadcrumbs">
            <div className="breadcrumbs-inner">
              <div className="breadcrumb-trail">
                <a href="home.html" className="trail-begin">
                  Home
                </a>
                <span className="sep">
                  <i className="finance-icon-chevron-right"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsSingleFeaturedTitle;
