import React from 'react';

function About() {
    return (
        <div>
            <div id="wrapper" className="animsition">
                <div id="page" className="clearfix">
                    <div className="hero-section slideshow text-center" data-height="840"
                         data-count="2"
                         data-image="assets/img/slider/slider-bg-4.jpg" data-effect="fade"
                         data-overlay=""
                         data-poverlay="">
                        <div className="hero-content">
                            <div className="themesflat-fancy-text typed"
                                 data-fancy="Services,Comunity,Analysis">
                                <h1 className="heading font-weight-700 letter-spacing--2">FINACE <span
                                    className="text">Servis</span></h1>
                            </div>

                            <div className="themesflat-spacer clearfix" data-desktop="21"
                                 data-mobi="21"
                                 data-smobi="21"></div>

                            <p className="sub-heading">We have years of experience helping
                                clients
                                find the
                                best
                                financing while meeting their financial goals.</p>

                            <div className="elm-btn">
                                <a href="/" className="themesflat-button accent">REQUEST A CALL
                                    BACK</a>
                            </div>
                        </div>
                    </div>
                    <div id="featured-title" className="featured-title clearfix" style={{marginTop: 100}}>
                        <div id="featured-title-inner" className="themesflat-container clearfix">
                            <div className="featured-title-inner-wrap">
                                <div className="featured-title-heading-wrap">
                                    <h1 className="featured-title-heading">
                                        ABOUT US
                                    </h1>
                                </div>
                                <div id="breadcrumbs">
                                    <div className="breadcrumbs-inner">
                                        <div className="breadcrumb-trail">
                                            <a href="/" className="trail-begin">Home</a>
                                            <span className="sep"><i className="finance-icon-chevron-right"></i></span>
                                            <span className="trail-end">ABOUT US</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="main-content" className="site-main clearfix">
                        <div id="content-wrap">
                            <div id="site-content" className="site-content clearfix">
                                <div id="inner-content" className="inner-content-wrap">
                                    <div className="page-content">
                                        <div className="row-video">
                                            <div className="container">
                                                <div className="themesflat-spacer clearfix" data-desktop="84"
                                                     data-mobi="60" data-smobi="60"></div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div
                                                            className="themesflat-headings style-1 clearfix text-center">
                                                            <h2 className="heading clearfix">OUR MAIN ABOUT</h2>
                                                            <div className="sep clearfix"></div>
                                                            <p className="sub-heading clearfix">Appropriate and
                                                                sufficient financing for a company is crucially
                                                                important for its long-term success. We advise you in
                                                                choosing the appropriate type of financing</p>
                                                        </div>

                                                        <div className="themesflat-spacer clearfix" data-desktop="52"
                                                             data-mobi="40" data-smobi="40"></div>
                                                    </div>
                                                </div>

                                                <div className="row equalize sm-equalize-auto">
                                                    <div className="col-md-6">

                                                        <div className="themesflat-tabs clearfix style-1">
                                                            <ul className="tab-title clearfix">
                                                                <li className="item-title active">
                                                                    <span className="inner">EXPERIENCE</span>
                                                                </li>
                                                                <li className="item-title">
                                                                    <span className="inner">INDEPENDENT</span>
                                                                </li>
                                                                <li className="item-title">
                                                                    <span className="inner">PRO-ACTIVE</span>
                                                                </li>
                                                            </ul>

                                                            <div className="tab-content-wrap">
                                                                <div className="tab-content">
                                                                    <div className="item-content">
                                                                        <h3 className="heading">WE HAVE A WIDE <a
                                                                            href="/">BREADTH OF EXPERIENCE</a> THAT
                                                                            COVERS MOST OF OUR CLIENTS' FINANCIAL
                                                                            CONCERNS.</h3>
                                                                        <p>We're proud to provide cutting-edge expertise
                                                                            in areas including planning, investments,
                                                                            taxes, estate, insurance, income and
                                                                            education. At Finance, our expertise and
                                                                            experience serve a single purpose: To help
                                                                            you succeed.</p>
                                                                        <ul>
                                                                            <li><i
                                                                                className="finance-icon-check-square"></i> We
                                                                                have a wide breadth of experience over
                                                                                30 years
                                                                            </li>
                                                                            <li><i
                                                                                className="finance-icon-check-square"></i> We
                                                                                are independent and free from third
                                                                                party influence
                                                                            </li>
                                                                            <li><i
                                                                                className="finance-icon-check-square"></i> We
                                                                                has a pro-active mindset in regards to
                                                                                client service
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="tab-content">
                                                                    <div className="item-content">
                                                                        <h3 className="heading">WE HAVE A WIDE BREADTH
                                                                            OF EXPERIENCE THAT COVERS MOST OF OUR
                                                                            CLIENTS' FINANCIAL CONCERNS.</h3>
                                                                        <p>We're proud to provide cutting-edge expertise
                                                                            in areas including planning, investments,
                                                                            taxes, estate, insurance, income and
                                                                            education. At Finance, our expertise and
                                                                            experience serve a single purpose: To help
                                                                            you succeed.</p>
                                                                        <ul>
                                                                            <li><i
                                                                                className="finance-icon-check-square"></i> We
                                                                                have a wide breadth of experience over
                                                                                30 years
                                                                            </li>
                                                                            <li><i
                                                                                className="finance-icon-check-square"></i> We
                                                                                are independent and free from third
                                                                                party influence
                                                                            </li>
                                                                            <li><i
                                                                                className="finance-icon-check-square"></i> We
                                                                                has a pro-active mindset in regards to
                                                                                client service
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>


                                                                <div className="tab-content">
                                                                    <div className="item-content">
                                                                        <h3 className="heading">WE HAVE A WIDE BREADTH
                                                                            OF EXPERIENCE THAT COVERS MOST OF OUR
                                                                            CLIENTS' FINANCIAL CONCERNS.</h3>
                                                                        <p>We're proud to provide cutting-edge expertise
                                                                            in areas including planning, investments,
                                                                            taxes, estate, insurance, income and
                                                                            education. At Finance, our expertise and
                                                                            experience serve a single purpose: To help
                                                                            you succeed.</p>
                                                                        <ul>
                                                                            <li><i
                                                                                className="finance-icon-check-square"></i> We
                                                                                have a wide breadth of experience over
                                                                                30 years
                                                                            </li>
                                                                            <li><i
                                                                                className="finance-icon-check-square"></i> We
                                                                                are independent and free from third
                                                                                party influence
                                                                            </li>
                                                                            <li><i
                                                                                className="finance-icon-check-square"></i> We
                                                                                has a pro-active mindset in regards to
                                                                                client service
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="themesflat-spacer clearfix" data-desktop="0"
                                                             data-mobi="40" data-smobi="40"></div>
                                                    </div>

                                                    <div className="col-md-6 half-background style-2">
                                                        <div className="themesflat-icon style-1 clearfix background">
                                                            <a className="icon-wrap popup-video"
                                                               href="https://www.youtube.com/watch?v=LQpedLhpQsk">
                                                                <span className="icon"><i
                                                                    className="finance-icon-playbutton"></i></span>
                                                            </a>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="themesflat-spacer clearfix" data-desktop="80"
                                                     data-mobi="60" data-smobi="60"></div>
                                            </div>
                                        </div>

                                        <div className="row-accordions bg-light-grey">
                                            <div className="container">
                                                <div className="row equalize sm-equalize-auto">
                                                    <div className="col-md-5">
                                                        <div className="themesflat-spacer clearfix" data-desktop="0"
                                                             data-mobi="60" data-smobi="60"></div>

                                                        <div className="img-single margin-top--60">
                                                            <img src="assets/img/img-single/img-single2.png"
                                                                 alt="ImageAbout"/>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-7">
                                                        <div className="themesflat-spacer clearfix" data-desktop="72"
                                                             data-mobi="40" data-smobi="40"></div>

                                                        <div
                                                            className="themesflat-headings style-1 clearfix padding-left-27">
                                                            <h2 className="heading clearfix">WHY CHOOSE OUR
                                                                COMPANY?</h2>
                                                            <div className="sep clearfix"></div>
                                                        </div>

                                                        <div className="themesflat-spacer clearfix" data-desktop="33"
                                                             data-mobi="40" data-smobi="40"></div>

                                                        <div className="themesflat-accordions style-3 padding-left-27">
                                                            <div className="accordion-item active no-icon">
                                                                <h3 className="accordion-heading"><span
                                                                    className="inner">WE HAVE A WIDE BREADTH OF EXPERIENCE OVER 30 YEARS</span>
                                                                </h3>

                                                                <div className="accordion-content">
                                                                    <div>Lorem ipsum dolor sit amet, consectetuer
                                                                        adipiscing elit sed diam nonummy nibh euismod
                                                                        tincid unt ut laoreet dolore magna aliquam erat
                                                                        volutpat. Ut wisi enim ad minim veniam, quis
                                                                        nostrud exerci tation ullamcorper suscipit
                                                                        lobortis nisl ut aliquip ex ea commodo
                                                                        consequat. Duis autem vel eum iriure dolor in
                                                                        hendrerit in vulputate velit esse molestie
                                                                        consequat, vel illum dolore eu feugiat
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="accordion-item no-icon">
                                                                <h3 className="accordion-heading"><span
                                                                    className="inner">WE ARE INDEPENDENT AND FREE FROM THIRD PARTY INFLUENCE</span>
                                                                </h3>

                                                                <div className="accordion-content">
                                                                    <div>Lorem ipsum dolor sit amet, consectetuer
                                                                        adipiscing elit sed diam nonummy nibh euismod
                                                                        tincid unt ut laoreet dolore magna aliquam erat
                                                                        volutpat. Ut wisi enim ad minim veniam, quis
                                                                        nostrud exerci tation ullamcorper suscipit
                                                                        lobortis nisl ut aliquip ex ea commodo
                                                                        consequat. Duis autem vel eum iriure dolor in
                                                                        hendrerit in vulputate velit esse molestie
                                                                        consequat, vel illum dolore eu feugiat
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="accordion-item no-icon">
                                                                <h3 className="accordion-heading"><span
                                                                    className="inner">WE HAS A PRO-ACTIVE MINDSET IN REGARDS TO CLIENT SERVICE</span>
                                                                </h3>

                                                                <div className="accordion-content">
                                                                    <div>Lorem ipsum dolor sit amet, consectetuer
                                                                        adipiscing elit sed diam nonummy nibh euismod
                                                                        tincid unt ut laoreet dolore magna aliquam erat
                                                                        volutpat. Ut wisi enim ad minim veniam, quis
                                                                        nostrud exerci tation ullamcorper suscipit
                                                                        lobortis nisl ut aliquip ex ea commodo
                                                                        consequat. Duis autem vel eum iriure dolor in
                                                                        hendrerit in vulputate velit esse molestie
                                                                        consequat, vel illum dolore eu feugiat
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="themesflat-spacer clearfix" data-desktop="60"
                                                             data-mobi="60" data-smobi="60"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="themesflat-row row-facts-1 parallax parallax-overlay">
                                            <div className="bg-parallax-overlay overlay-black"></div>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="themesflat-spacer clearfix" data-desktop="150"
                                                             data-mobi="60" data-smobi="60"></div>
                                                    </div>


                                                    <div className="col-md-4">
                                                        <div className="themesflat-animation-block"
                                                             data-animate="fadeInUpSmall" data-duration="1s"
                                                             data-delay="0" data-position="80%">
                                                            <div
                                                                className="themesflat-counter style-4 mobi-center clearfix icon-top margin-top--42">
                                                                <div className="inner">
                                                                    <div className="sub-heading">FINANCE GROUP</div>

                                                                    <div className="text-wrap">
                                                                        <div className="number-wrap font-heading">
                                                                            <span
                                                                                className="prefix">FOUNDED IN </span><span
                                                                            className="number accent" data-speed="3000"
                                                                            data-to="1988"
                                                                            data-inviewport="yes">1988</span><span
                                                                            className="suffix">.</span>
                                                                        </div>

                                                                        <div className="sep"></div>
                                                                        <h3 className="heading">We become one of the
                                                                            best financial services providers. Our team
                                                                            has decades of experience working
                                                                            specifically with affluent individuals.
                                                                            We've devoted our careers to helps clients
                                                                            reach their financial goals.</h3>
                                                                        <a href="/" className="simple-link">READ MORE
                                                                            ABOUT US</a>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>


                                                        <div className="themesflat-spacer clearfix" data-desktop="0"
                                                             data-mobi="40" data-smobi="40"></div>
                                                    </div>

                                                    <div className="col-md-8">
                                                        <div className="col span_1_of_3 relative">
                                                            <div className="themesflat-animation-block"
                                                                 data-animate="fadeInUpSmall" data-duration="1s"
                                                                 data-delay="0.15s" data-position="80%">
                                                                <div
                                                                    className="themesflat-counter style-2 clearfix icon-top text-center">
                                                                    <div className="inner">
                                                                        <div className="icon-wrap">
                                                                            <div className="icon"><i
                                                                                className="finance-icon-bulb"></i></div>
                                                                        </div>

                                                                        <div className="text-wrap">
                                                                            <div className="number-wrap font-heading">
                                                                                <span className="prefix"></span><span
                                                                                className="number accent"
                                                                                data-speed="3000" data-to="7513"
                                                                                data-inviewport="yes">7513</span><span
                                                                                className="suffix">+</span>
                                                                            </div>

                                                                            <div className="sep"></div>
                                                                            <h3 className="heading">HAPPY CUSTOMERS</h3>
                                                                        </div>

                                                                        <div className="border-right-2"></div>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div className="themesflat-spacer clearfix" data-desktop="0"
                                                                 data-mobi="40" data-smobi="40"></div>
                                                        </div>


                                                        <div className="col span_1_of_3 relative">
                                                            <div className="themesflat-animation-block"
                                                                 data-animate="fadeInUpSmall" data-duration="1s"
                                                                 data-delay="0.3s" data-position="80%">
                                                                <div
                                                                    className="themesflat-counter style-2 clearfix icon-top text-center">
                                                                    <div className="inner">
                                                                        <div className="icon-wrap">
                                                                            <div className="icon"><i
                                                                                className="finance-icon-businessman-1"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="text-wrap">
                                                                            <div className="number-wrap font-heading">
                                                                                <span className="prefix"></span><span
                                                                                className="number accent"
                                                                                data-speed="3000" data-to="1290"
                                                                                data-inviewport="yes">1290</span><span
                                                                                className="suffix">+</span>
                                                                            </div>

                                                                            <div className="sep"></div>
                                                                            <h3 className="heading">EXPERT ADVISORS</h3>

                                                                            <div className="border-right-2"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div className="themesflat-spacer clearfix" data-desktop="0"
                                                                 data-mobi="40" data-smobi="40"></div>
                                                        </div>


                                                        <div className="col span_1_of_3 relative">
                                                            <div className="themesflat-animation-block"
                                                                 data-animate="fadeInUpSmall" data-duration="1s"
                                                                 data-delay="0.45s" data-position="80%">
                                                                <div
                                                                    className="themesflat-counter style-2 clearfix icon-top text-center">
                                                                    <div className="inner">
                                                                        <div className="icon-wrap">
                                                                            <div className="icon"><i
                                                                                className="finance-icon-award"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="text-wrap">
                                                                            <div className="number-wrap font-heading">
                                                                                <span className="prefix"></span><span
                                                                                className="number accent"
                                                                                data-speed="3000" data-to="2684"
                                                                                data-inviewport="yes">2684</span><span
                                                                                className="suffix">+</span>
                                                                            </div>

                                                                            <div className="sep"></div>
                                                                            <h3 className="heading">PROJECT SUCCESS</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>


                                                    <div className="col-md-12">
                                                        <div className="themesflat-spacer clearfix" data-desktop="113"
                                                             data-mobi="60" data-smobi="60"></div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>


                                        <div className="row-testimonials">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="themesflat-spacer clearfix" data-desktop="93"
                                                             data-mobi="60" data-smobi="60"></div>

                                                        <div
                                                            className="themesflat-headings style-1 clearfix text-center">
                                                            <h2 className="heading clearfix">CLIENT TESTIMONIALS</h2>
                                                            <div className="sep clearfix"></div>
                                                            <p className="sub-heading clearfix">Appropriate and
                                                                sufficient financing for a company is crucially
                                                                important for its long-term success. We advise you in
                                                                choosing the appropriate type of financing</p>
                                                        </div>

                                                        <div className="themesflat-spacer clearfix" data-desktop="52"
                                                             data-mobi="40" data-smobi="40"></div>

                                                        <div
                                                            className="themesflat-carousel-box testimonials-s2 has-bullets bullet-circle"
                                                            data-auto="true" data-loop="false" data-gap="25"
                                                            data-column="2" data-column2="1" data-column3="1">
                                                            <div className="owl-carousel owl-theme">
                                                                <div
                                                                    className="themesflat-testimonials style-2 clearfix image-circle">
                                                                    <div className="item">
                                                                        <div className="inner">
                                                                            <div className="thumb">
                                                                                <img
                                                                                    src="assets/img/testimonials/customer-2.png"
                                                                                    alt="ImageAbout"/>
                                                                            </div>

                                                                            <blockquote className="text">
                                                                                <div className="start">
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                </div>
                                                                                <p>I would like to say how thankful I am
                                                                                    for Finance Group. I have been a
                                                                                    valued customer there for many
                                                                                    years. They have helped me when some
                                                                                    of the major lenders would not
                                                                                    because of a credit score. I
                                                                                    appreciate them so much. They gave
                                                                                    me the opportunity to build up trust
                                                                                    and repay my bill. I have
                                                                                    recommended Finance Group too many
                                                                                    of my friends and coworkers.</p>
                                                                                <div className="name-pos">
                                                                                    <h6 className="name">SABRINA
                                                                                        BROWN</h6>
                                                                                    <span className="position">Delicates Studio</span>
                                                                                </div>
                                                                            </blockquote>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="themesflat-testimonials style-2 clearfix image-circle">
                                                                    <div className="item">
                                                                        <div className="inner">
                                                                            <div className="thumb">
                                                                                <img
                                                                                    src="assets/img/testimonials/customer-3.png"
                                                                                    alt="ImageAbout"/>
                                                                            </div>

                                                                            <blockquote className="text">
                                                                                <div className="start">
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                </div>
                                                                                <p>I would like to say how thankful I am
                                                                                    for Finance Group. I have been a
                                                                                    valued customer there for many
                                                                                    years. They have helped me when some
                                                                                    of the major lenders would not
                                                                                    because of a credit score. I
                                                                                    appreciate them so much. They gave
                                                                                    me the opportunity to build up trust
                                                                                    and repay my bill. I have
                                                                                    recommended Finance Group too many
                                                                                    of my friends and coworkers.</p>
                                                                                <div className="name-pos">
                                                                                    <h6 className="name">SABRINA
                                                                                        BROWN</h6>
                                                                                    <span className="position">Delicates Studio</span>
                                                                                </div>
                                                                            </blockquote>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="themesflat-testimonials style-2 clearfix image-circle">
                                                                    <div className="item">
                                                                        <div className="inner">
                                                                            <div className="thumb">
                                                                                <img
                                                                                    src="assets/img/testimonials/customer-2.png"
                                                                                    alt="ImageAbout"/>
                                                                            </div>

                                                                            <blockquote className="text">
                                                                                <div className="start">
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                </div>
                                                                                <p>I would like to say how thankful I am
                                                                                    for Finance Group. I have been a
                                                                                    valued customer there for many
                                                                                    years. They have helped me when some
                                                                                    of the major lenders would not
                                                                                    because of a credit score. I
                                                                                    appreciate them so much. They gave
                                                                                    me the opportunity to build up trust
                                                                                    and repay my bill. I have
                                                                                    recommended Finance Group too many
                                                                                    of my friends and coworkers.</p>
                                                                                <div className="name-pos">
                                                                                    <h6 className="name">SABRINA
                                                                                        BROWN</h6>
                                                                                    <span className="position">Delicates Studio</span>
                                                                                </div>
                                                                            </blockquote>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="themesflat-testimonials style-2 clearfix image-circle">
                                                                    <div className="item">
                                                                        <div className="inner">
                                                                            <div className="thumb">
                                                                                <img
                                                                                    src="assets/img/testimonials/customer-3.png"
                                                                                    alt="ImageAbout"/>
                                                                            </div>

                                                                            <blockquote className="text">
                                                                                <div className="start">
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                </div>
                                                                                <p>I would like to say how thankful I am
                                                                                    for Finance Group. I have been a
                                                                                    valued customer there for many
                                                                                    years. They have helped me when some
                                                                                    of the major lenders would not
                                                                                    because of a credit score. I
                                                                                    appreciate them so much. They gave
                                                                                    me the opportunity to build up trust
                                                                                    and repay my bill. I have
                                                                                    recommended Finance Group too many
                                                                                    of my friends and coworkers.</p>
                                                                                <div className="name-pos">
                                                                                    <h6 className="name">SABRINA
                                                                                        BROWN</h6>
                                                                                    <span className="position">Delicates Studio</span>
                                                                                </div>
                                                                            </blockquote>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="themesflat-testimonials style-2 clearfix image-circle">
                                                                    <div className="item">
                                                                        <div className="inner">
                                                                            <div className="thumb">
                                                                                <img
                                                                                    src="assets/img/testimonials/customer-2.png"
                                                                                    alt="ImageAbout"/>
                                                                            </div>

                                                                            <blockquote className="text">
                                                                                <div className="start">
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                </div>
                                                                                <p>I would like to say how thankful I am
                                                                                    for Finance Group. I have been a
                                                                                    valued customer there for many
                                                                                    years. They have helped me when some
                                                                                    of the major lenders would not
                                                                                    because of a credit score. I
                                                                                    appreciate them so much. They gave
                                                                                    me the opportunity to build up trust
                                                                                    and repay my bill. I have
                                                                                    recommended Finance Group too many
                                                                                    of my friends and coworkers.</p>
                                                                                <div className="name-pos">
                                                                                    <h6 className="name">SABRINA
                                                                                        BROWN</h6>
                                                                                    <span className="position">Delicates Studio</span>
                                                                                </div>
                                                                            </blockquote>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="themesflat-testimonials style-2 clearfix image-circle">
                                                                    <div className="item">
                                                                        <div className="inner">
                                                                            <div className="thumb">
                                                                                <img
                                                                                    src="assets/img/testimonials/customer-3.png"
                                                                                    alt="ImageAbout"/>
                                                                            </div>

                                                                            <blockquote className="text">
                                                                                <div className="start">
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                    <i className="finance-icon-star"></i>
                                                                                </div>
                                                                                <p>I would like to say how thankful I am
                                                                                    for Finance Group. I have been a
                                                                                    valued customer there for many
                                                                                    years. They have helped me when some
                                                                                    of the major lenders would not
                                                                                    because of a credit score. I
                                                                                    appreciate them so much. They gave
                                                                                    me the opportunity to build up trust
                                                                                    and repay my bill. I have
                                                                                    recommended Finance Group too many
                                                                                    of my friends and coworkers.</p>
                                                                                <div className="name-pos">
                                                                                    <h6 className="name">SABRINA
                                                                                        BROWN</h6>
                                                                                    <span className="position">Delicates Studio</span>
                                                                                </div>
                                                                            </blockquote>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="themesflat-spacer clearfix" data-desktop="87"
                                                             data-mobi="60" data-smobi="60"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row-promotion bg-accent">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="themesflat-spacer clearfix" data-desktop="25"
                                                             data-mobi="40" data-smobi="40"></div>

                                                        <div className="themesflat-action-box style-1 has-icon">
                                                            <div className="inner">
                                                                <div className="heading-wrap">
                                                                    <div className="text-wrap">
                                                                        <span
                                                                            className="icon finance-icon-award"></span>
                                                                        <h3 className="heading mobi-padding-top20 mobi-padding-bottom20">
                                                                            PROFESSIONAL HELP IN PLANNING YOUR FINANCIAL
                                                                            FUTURE
                                                                        </h3>
                                                                        <span className="icon"><i
                                                                            className="as-icon-speedometer2"></i></span>
                                                                    </div>
                                                                </div>
                                                                <div className="button-wrap">
                                                                    <a href="/"
                                                                       className="themesflat-button white font-weight-600 margin-top-10 margin-bottom-13">CALL
                                                                        US TODAY</a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="themesflat-spacer clearfix" data-desktop="20"
                                                             data-mobi="40" data-smobi="40"></div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;