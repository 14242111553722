export const API_URL = "https://newbackend-family.napaautomotive.uz/api";
export const API_URLNEW = "https://newbackend-family.napaautomotive.uz/api";
export const API_IMG = "https://newbackend-family.napaautomotive.uz/storage/";
export const BASE_URL = `http://${API_URL}/admin/`;
export const AUTH_BASE_URL = `http://${API_URL}/`;
export const API_IMGBOOK =
  "https://newbackend-family.napaautomotive.uz/storage/";
export const API_IMGVIDEO =
  "https://newbackend-family.napaautomotive.uz/storage/";
// newbackend-family.napaautomotive.uz
// admin.yoshoila.uz
