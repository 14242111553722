/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../constants/variables";
import "./doniy.css";

const PaginationNews = ({ postsPerPage, totalPosts, paginate }) => {
  const [todos, settodos] = useState();
  useEffect(() => {
    axios({
      method: "get",
      url: API_URL + "/category",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      settodos(res.data.category.reverse());
    });
  }, []);

  return (
    <ul className="doniy-hight">
      {todos?.map((number) => (
        <li key={number.id} onClick={() => paginate(number.id)}>
          {number.name_uz}
        </li>
      ))}
    </ul>
  );
};

export default PaginationNews;
