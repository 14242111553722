import FooterFotoPotok from "../Footer/FooterFotoPotok";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_IMG, API_URL } from "../../constants/variables";
import { useTranslation } from "react-i18next";
// import ResizeImage from 'react-resize-image'
import Compress from "react-image-file-resizer";
// import * as url from "url";

const NewsSingleSideBar = () => {
  const [news, setNews] = useState([]);
  const language = localStorage.getItem("lang");
  const [newsId, setNewsId] = useState({});
  const [tags, setTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    axios.get(API_URL + "/category").then((res) => {
      if (language == "ru") {
        setNewsId(
          res.data.category.find(
            (item) => item.name_ru.toLowerCase() == "Новости"?.toLowerCase()
          )
        );
      } else {
        setNewsId(
          res.data.category.find(
            (item) => item.name_uz.toLowerCase() == "Yangiliklar"?.toLowerCase()
          )
        );
      }
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      axios({
        method: "get",
        url: API_URL + "/posts/" + newsId.id,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      }).then((res) => {
        setNews(res.data.posts);
      });
    }, 1000);
  });

  useEffect(() => {
    axios.get(API_URL + "/tags").then((res) => setTags(res.data.tag));
  }, []);

  const sorted = [];
  for (let i = 0; i < tags.length; i++) {
    sorted.push(tags[i].name_uz?.toLowerCase());
  }

  const sortedRu = [];
  for (let r = 0; r < tags.length; r++) {
    sortedRu.push(tags[r].name_ru?.toLowerCase());
  }

  const uniqueSort = [...new Set(sorted)];
  const uniqueSortRu = [...new Set(sortedRu)];

  const handleSingle = (name) => {
    localStorage.setItem("tegName", name);
  };

  const handleSearcher = (name) => {
    localStorage.setItem("tegName", name);
    name.preventDefault();
    setSearchTerm("");
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  function resizeImg(img) {
    const file = img;
    let newUrl;
    Compress.imageFileResizer(
      file, // the file from input
      73, // width
      73, // height
      "JPEG", // compress format WEBP, JPEG, PNG
      100, // quality
      0, // rotation
      (uri) => {
        newUrl = uri;
        // You upload logic goes here
      },
      "base64" // blob or base64 default base64
    );
    return newUrl;
  }
  return (
    <div className="col-md-3">
      <div id="main-content" className="site-main clearfix">
        <div id="content-wrap" className="themesflat-container">
          <div id="sidebar">
            <div id="inner-sidebar" className="inner-content-wrap">
              <div className="widget widget_search margin-bottom-55"></div>
              <div className="widget widget_instagram margin-bottom-48">
                <FooterFotoPotok />
              </div>
              <div className="widget widget_tag_cloud">
                <h2 className="widget-title">
                  <span>{t("SediBar.tag")}</span>
                </h2>
                <div className="tagcloud">
                  {uniqueSort &&
                    uniqueSort.map((item, i) => (
                      <a
                        onClick={() => handleSingle(item)}
                        key={i}
                        href="/tegs"
                      >
                        {language === "uz" ? item : uniqueSortRu[i]}
                      </a>
                    ))}
                </div>
              </div>
              <div className="span_1_of_4 col">
                <div className="widget widget_twitter margin-top-6 padding-left-13">
                  <h2 className="widget-title">
                    <span>{t("Menu.menu31")}</span>
                  </h2>
                  <ul className="tweets clearfix">
                    {news &&
                      news.map((item, i) => (
                        <li key={i} className="item clearfix">
                          <div className="tweet-icon">
                            <img
                              className="img"
                              style={{
                                objectFit: "cover",
                                height: "73px",
                                width: "73px",
                              }}
                              src={`${API_IMG + item.image}`}
                              alt="ImageFooter"
                            />
                          </div>
                          <div className="texts">
                            <h3>
                              <a href="/newsSingle" style={{ color: "black" }}>
                                {language === "uz"
                                  ? item.title_uz
                                  : item.title_ru}
                              </a>
                            </h3>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsSingleSideBar;
