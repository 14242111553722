import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { API_URL } from "../../../constants/variables";
import { useTranslation } from "react-i18next";
import { Pagination } from "@material-ui/lab";
import "./video.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const getNews = ({ setTotal, setNews, page }) => {
  axios({
    method: "get",
    url: API_URL + "/videos" + "?page=" + page,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    setTotal(res.data.total);
    setNews(res.data.data);
  });
};

function Video() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const language = localStorage.getItem("lang");
  useEffect(() => {
    getNews({ setTotal, setNews, page });
  }, []);

  const handleSingle = (id) => {
    localStorage.setItem("newsId", id);
  };

  const handleChange = (event, value) => {
    setPage(value);
    getNews({ setTotal, setNews, page: value });
  };

  const paginationCount = Math.ceil(total / 9);
  console.log(news);
  return (
    <div>
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div
                style={{
                  height: "100px",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                }}
              ></div>
              <div id="featured-title" className="featured-title clearfix">
                <div
                  id="featured-title-inner"
                  className="themesflat-container clearfix"
                >
                  <div className="featured-title-inner-wrap">
                    <div className="featured-title-heading-wrap">
                      <h1 className="featured-title-heading">
                        {t("Video.video")}
                      </h1>
                    </div>
                    <div id="breadcrumbs">
                      <div className="breadcrumbs-inner">
                        <div className="breadcrumb-trail">
                          <a href="home.html" className="trail-begin">
                            {t("News.home")}
                          </a>
                          <span className="sep">
                            <i className="finance-icon-chevron-right"> </i>
                          </span>
                          {/* <span className="trail-end"> {t('Video.video')}</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <div className="row-services bg-light-grey">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="themesflat-news " data-gap="35">
                              {news.length > 0
                                ? news.map((item, index) => (
                                    <div
                                      className="col-md-4"
                                      style={{ marginBottom: "60px" }}
                                      key={index}
                                    >
                                      <div className="news-item style-1 clearfix">
                                        <div className="inner">
                                          <div className="thumb">
                                            <iframe
                                              width="100%"
                                              height="263px"
                                              src={item.video_uz}
                                              title="YouTube video player"
                                              allowFullScreen="allowFullScreen"
                                              frameBorder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            >
                                              {" "}
                                            </iframe>

                                            <h3
                                              style={{
                                                transform: "translateY(40px)",
                                              }}
                                              className="title"
                                            >
                                              <a
                                                onClick={() =>
                                                  handleSingle(item.id)
                                                }
                                                href="#"
                                              >
                                                {language === "uz"
                                                  ? item.title_uz
                                                      ?.split(" ")
                                                      ?.slice(0, 4)
                                                      ?.join(" ") + "..."
                                                  : item.title_ru}
                                              </a>
                                            </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                : null}
                            </div>
                          </div>
                          {/* <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop="90"
                              data-mobi="60"
                              data-smobi="60"
                            ></div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {total > 0 ? (
                <div className="video-pagination-box">
                  <Pagination
                    count={paginationCount}
                    page={page}
                    onChange={handleChange}
                    variant="outlined"
                    color="primary"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;
