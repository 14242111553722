import NewsSingleHeroSection from "../../NewsSingle/NewsSingleHeroSection";
import NewsSingleFeaturedTitle from "../../NewsSingle/NewsSingleFeaturedTitle";
import TeenagePeriodSiteContent from "./TeenagePeriodSiteContent";
import NewsSingleSideBar from "../../NewsSingle/NewsSingleSideBar";
import MedicalSiteContent from "../Medical/MedicalSiteContent";
import {useEffect, useState} from "react";
import {API_URL} from "../../../constants/variables";
import axios from "axios";
import { useTranslation } from "react-i18next";

const TeenagePeriod = () => {
    const { t } = useTranslation();
    const categoryId = localStorage.getItem('categoryId')
    const [post, setPost] = useState({});


    useEffect(() => {
        if (categoryId != null) {
            axios.get(API_URL + "/posts/" + categoryId).then(res => {
                console.log(res)
                setPost(res.data.posts[0])
            })
        }

    }, [])

    return (
        <div>
            <div id="wrapper" className="animsition">
                <div id="page" className="clearfix">
                    <div id="content-wrap">
                        <div style={{height: '100px', backgroundColor: 'rgba(0, 0, 0, 0.7)'}}></div>
                        <NewsSingleFeaturedTitle
                            pageName={t('Menu.menu20')}
                            pageSection={t('Menu.menu23')}/>
                        <div id="main-content" className="site-main clearfix">

                            <div id="site-content" className="site-content clearfix">
                                <div id="inner-content" className="inner-content-wrap">
                                    <div className="page-content">

                                        <div className="row-accordion">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="themesflat-spacer clearfix" data-desktop="91"
                                                             data-mobi="60" data-smobi="60"></div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <MedicalSiteContent post={post}/>
                                                    <NewsSingleSideBar/>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="themesflat-spacer clearfix" data-desktop="91"
                                                             data-mobi="60" data-smobi="60"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeenagePeriod;