import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Pagination from "./Pagination";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Posts from "./Posts";
import { API_URL } from "../../../constants/variables";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Books() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  // const language = localStorage.getItem('lang')
  useEffect(() => {
    axios({
      method: "get",
      url: API_URL + "/books",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setNews(res.data.book);
    });
  }, []);
  // console.log(news);
  let number = 1;
  news.forEach((i) => {
    i.createId = number++;
  });
  // console.log(news);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = news.slice(indexOfFirstPost, indexOfLastPost);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div
                style={{
                  height: "100px",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                }}
              >
                {" "}
              </div>
              <div id="featured-title" className="featured-title clearfix">
                <div
                  id="featured-title-inner"
                  className="themesflat-container clearfix"
                >
                  <div className="featured-title-inner-wrap">
                    <div className="featured-title-heading-wrap">
                      <h1 className="featured-title-heading">
                        {t("Books.books")}
                      </h1>
                    </div>
                    <div id="breadcrumbs">
                      <div className="breadcrumbs-inner">
                        <div className="breadcrumb-trail">
                          <a href="home.html" className="trail-begin">
                            {t("News.home")}
                          </a>
                          <span className="sep">
                            <i className="finance-icon-chevron-right"> </i>
                          </span>
                          {/* <span className="trail-end"> {t('Books.books')}</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <div className="row-services bg-light-grey">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="themesflat-news" data-gap="35">
                              <Posts news={currentPosts} loading={loading} />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop="90"
                              data-mobi="60"
                              data-smobi="60"
                            >
                              <Pagination
                                postsPerPage={postsPerPage}
                                totalPosts={news.length}
                                paginate={paginate}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Books;
