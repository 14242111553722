const ServiceRowServices = () => {
    return (
        <div className="row-services">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="themesflat-spacer clearfix" data-desktop="92" data-mobi="60" data-smobi="60"></div>
                        <div className="themesflat-headings style-1 clearfix text-center padding-left-3">
                            <h2 className="heading clearfix">BEST SERVICES OFFER</h2>
                            <div className="sep clearfix"></div>
                            <p className="sub-heading clearfix">Appropriate and sufficient financing for a company is crucially important for its long-term success. We advise you in choosing the appropriate type of financing</p>
                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop="52" data-mobi="40" data-smobi="40"></div>
                    </div>
                    <div className="col-md-12">
                        <div className="themesflat-news bullet-circle has-bullets" data-auto="false" data-column="3" data-column2="2" data-column3="1" data-gap="35">
                            <div className="owl-carousel owl-theme">
                                <div className="news-item style-1 text-center clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <img src="assets/img/news/post-4.jpg" alt="ImageServiceRow"/>
                                            <h3 className="title bg-gradient font-size-16 padding-top-30 padding-bottom-10">
                                                <a href="page-blog-single.html">INVESTMENT LOAN</a>
                                            </h3>
                                        </div>
                                        <div className="text-wrap bg-light-grey">
                                            <p className="excerpt-text">Loan business play a vital role in keeping your business competitive. In many cases, such measures and investments require capital</p>
                                            <div className="post-btn">
                                                <a href="page-blog-single.html" className="simple-link">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-item style-1 text-center clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <img src="assets/img/news/post-5.jpg" alt="ImageServiceRow"/>
                                            <h3 className="title bg-gradient font-size-16 padding-top-30 padding-bottom-10">
                                                <a href="page-blog-single.html">Project Finance</a>
                                            </h3>
                                        </div>
                                        <div className="text-wrap bg-light-grey">
                                            <p className="excerpt-text">We aim at developing and implementing customized and sustainable project financing solutions together with our clients</p>
                                            <div className="post-btn">
                                                <a href="page-blog-single.html" className="simple-link">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-item style-1 text-center clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <img src="assets/img/news/post-6.jpg" alt="ImageServiceRow"/>
                                            <h3 className="title bg-gradient font-size-16 padding-top-30 padding-bottom-10">
                                                <a href="page-blog-single.html">REAL ESTATE PLANNING</a>
                                            </h3>
                                        </div>
                                        <div className="text-wrap bg-light-grey">
                                            <p className="excerpt-text">Finance focuses its services on a wide variety of financing solutions as well as for real estate project development and real estate projects.</p>
                                            <div className="post-btn">
                                                <a href="page-blog-single.html" className="simple-link">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-item style-1 text-center clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <img src="assets/img/news/post-4.jpg" alt="ImageServiceRow"/>
                                            <h3 className="title bg-gradient font-size-16 padding-top-30 padding-bottom-10">
                                                <a href="page-blog-single.html">INVESTMENT LOAN</a>
                                            </h3>
                                        </div>
                                        <div className="text-wrap bg-light-grey">
                                            <p className="excerpt-text">Loan business play a vital role in keeping your business competitive. In many cases, such measures and investments require capital</p>
                                            <div className="post-btn">
                                                <a href="page-blog-single.html" className="simple-link">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-item style-1 text-center clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <img src="assets/img/news/post-5.jpg" alt="ImageServiceRow"/>
                                            <h3 className="title bg-gradient font-size-16 padding-top-30 padding-bottom-10">
                                                <a href="page-blog-single.html">Project Finance</a>
                                            </h3>
                                        </div>
                                        <div className="text-wrap bg-light-grey">
                                            <p className="excerpt-text">We aim at developing and implementing customized and sustainable project financing solutions together with our clients</p>
                                            <div className="post-btn">
                                                <a href="page-blog-single.html" className="simple-link">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-item style-1 text-center clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <img src="assets/img/news/post-6.jpg" alt="ImageServiceRow"/>
                                            <h3 className="title bg-gradient font-size-16 padding-top-30 padding-bottom-10">
                                                <a href="page-blog-single.html">REAL ESTATE PLANNING</a>
                                            </h3>
                                        </div>
                                        <div className="text-wrap bg-light-grey">
                                            <p className="excerpt-text">Finance focuses its services on a wide variety of financing solutions as well as for real estate project development and real estate projects.</p>
                                            <div className="post-btn">
                                                <a href="page-blog-single.html" className="simple-link">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="news-item style-1 text-center clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <img src="assets/img/news/post-4.jpg" alt="ImageServiceRow"/>
                                            <h3 className="title bg-gradient font-size-16 padding-top-30 padding-bottom-10">
                                                <a href="page-blog-single.html">INVESTMENT LOAN</a>
                                            </h3>
                                        </div>
                                        <div className="text-wrap bg-light-grey">
                                            <p className="excerpt-text">Loan business play a vital role in keeping your business competitive. In many cases, such measures and investments require capital</p>
                                            <div className="post-btn">
                                                <a href="page-blog-single.html" className="simple-link">READ MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="themesflat-spacer clearfix" data-desktop="97" data-mobi="60" data-smobi="60"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ServiceRowServices;