const ServiceRowPartners = () => {
    return (
        <div className="row-partner bg-accent">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="themesflat-spacer clearfix" data-desktop="34" data-mobi="40" data-smobi="40"></div>
                        <div className="themesflat-partner style-3 offset30 offset-v0"
                             data-auto="false"
                             data-loop="false"
                             data-column="5"
                             data-column2="3"
                             data-column3="2"
                             data-gap="60">
                            <div className="owl-carousel owl-theme">
                                <div className="partner-item clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} className="thumb">
                                                <img style={{width: '50px',}}
                                                     src="./assets/img/partners/gerb.webp"
                                                     alt="albom"/>
                                                <div>
                                                    <a style={{color: 'white'}}
                                                       target="_blank"
                                                       href="https://davxizmat.uz">Yoshlar ishlari agentligi</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="partner-item clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                                                className="thumb">
                                                <img style={{width: '35px',}} src="./assets/img/partners/prokuratura.webp" alt="albom"/>
                                                <div style={{textAlign:'center'}}>
                                                    <a style={{color: 'white'}}
                                                       target="_blank"
                                                       href="https://prokuratura.uz">Bosh prokuratura</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="partner-item clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} className="thumb">
                                                <img style={{width: '50px',}} src="./assets/img/partners/gerb.webp" alt="albom"/>
                                                <div style={{textAlign:'center'}}>
                                                    <a style={{color: 'white'}}
                                                       target="_blank"
                                                       href="https://davxizmat.uz">Davlat xizmatlar agentligi</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="partner-item clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} className="thumb">
                                                <img style={{width: '50px',}} src="./assets/img/partners/gerb.webp" alt="albom"/>
                                                <div>
                                                    <a style={{color: 'white'}}
                                                       target="_blank"
                                                       href="https://www.minjust.uz">Adliya vazirligi</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="partner-item clearfix">
                                    <div className="inner">
                                        <div className="thumb">
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} className="thumb">
                                                <img style={{width: '80px',}} src="./assets/img/partners/statistika.webp" alt="albom"/>
                                                <div>
                                                    <a style={{color: 'white'}}
                                                       target="_blank"
                                                       href="https://www.stat.uz">Statistika qo'mitasi</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop="25" data-mobi="40" data-smobi="40"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ServiceRowPartners;