import React, {useEffect, useState} from 'react';
import '../../i18n'
import axios from "axios";
import Aos from 'aos'
import "aos/dist/aos.css"
import Religin from '../../image/2987.webp';
import {API_URL} from "../../constants/variables";

function HomeVideo() {
    const [content, setContent] = useState([]);
    let language;
    if(localStorage.getItem('lang')){
        language = localStorage.getItem('lang')
    } else {
        localStorage.setItem('lang','uz')
        language = localStorage.getItem('lang')
    }
    useEffect(() => {
        axios.get(API_URL + "/page/0").then(res => {
            setContent(res.data.page);
        Aos.init({duration:2000});
        })
    }, [])
    return (
        <div>
            <div className="row-video">
                <div className="container">
                    <div className="themesflat-spacer clearfix" data-desktop="104" data-mobi="60" data-smobi="60"></div>
                    <div data-aos="fade-up-right" className="row equalize sm-equalize-auto">
                        <div style={{padding:'0 5% 0 5%', background:'#F7F7F7'}} className="col-md-6">
                            <div className="themesflat-spacer clearfix" data-desktop="33" data-mobi="0" data-smobi="0"></div>
                            <div className="themesflat-headings style-1 clearfix" style={{textAlign:'justify'}}>
                                {/*<div>*/}
                                {/*    <img src={`${API_IMG + content[0].image == undefined ? '' : content[0].image}`}*/}
                                {/*         alt=""/>*/}
                                {/*</div>*/}
                                <h2 className="heading letter-spacing--09px clearfix" style={{marginTop: "20px"}}>
                                    {content[0] && language === 'uz' ? content[0].title_uz : content[0] && content[0].title_ru}
                                </h2>
                                <div className="sep clearfix"></div>
                                <p className="sub-heading clearfix">
                                    <div dangerouslySetInnerHTML={{
                                            __html: content[0] && language === 'uz' ? content[0].content_uz : content[0] && content[0].content_ru
                                        }}></div>
                                </p>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop="20" data-mobi="20" data-smobi="20"></div>
                            <div className="themesflat-spacer clearfix" data-desktop="21" data-mobi="20" data-smobi="20"></div>
                            <div className="themesflat-spacer clearfix" data-desktop="28" data-mobi="60" data-smobi="60"></div>
                        </div>
                        <div  className="col-md-6 half-background style-1 huquq"></div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop="80" data-mobi="60" data-smobi="60"></div>
                    <div className="border-h-1 dark"></div>
                </div>
            </div>
            <div className="row-video">
                <div className="container">
                    <div className="themesflat-spacer clearfix" data-desktop="104" data-mobi="60" data-smobi="60"></div>
                    <div data-aos="fade-up-left" className="row equalize sm-equalize-auto">
                        <div style={{
                                        backgroundImage:`url(${Religin})`,
                                        backgroundSize:'cover',
                                        backgroundPosition:'center',
                                        backgroundRepeat:'no-repeat'
                                    }}
                             className="col-md-6 half-background style-1 religin"></div>
                        <div className="col-md-6 " style={{padding:"0 5% 0 5%", textAlign:'justify', background:'#F7F7F7'}}>
                            <div className="themesflat-spacer clearfix" data-desktop="33" data-mobi="0" data-smobi="0"></div>
                            <div className="themesflat-headings style-1 clearfix">
                                <h2 className="heading letter-spacing--09px clearfix" style={{marginTop: "20px"}}>
                                    {content[1] && language === 'uz' ? content[1].title_uz : content[1] && content[1].title_ru}
                                </h2>
                                <div className="sep clearfix"></div>
                                <p className="sub-heading clearfix">
                                    <div dangerouslySetInnerHTML={{
                                            __html: content[1] && language === 'uz' ? content[1].content_uz : content[1] && content[1].content_ru
                                        }}></div>
                                </p>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop="20" data-mobi="20" data-smobi="20"></div>
                            <div className="themesflat-spacer clearfix" data-desktop="21" data-mobi="20" data-smobi="20"></div>
                            <div className="themesflat-spacer clearfix" data-desktop="28" data-mobi="60" data-smobi="60"></div>
                        </div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop="80" data-mobi="60" data-smobi="60"></div>
                    <div className="border-h-1 dark"></div>
                </div>
            </div>
            <div className="row-video">
                <div className="container">
                    <div className="themesflat-spacer clearfix" data-desktop="104" data-mobi="60" data-smobi="60"></div>
                    <div className="row equalize sm-equalize-auto">
                        <div data-aos="flip-right" style={{padding:'0 4% 0 5%', background:'#F7F7F7'}} className="col-md-6">
                            <div className="themesflat-spacer clearfix" data-desktop="33" data-mobi="0" data-smobi="0"></div>
                            <div className="themesflat-headings style-1 clearfix" style={{paddingRight: "15px", textAlign:'justify'}}>
                                <h2 className="heading letter-spacing--09px clearfix" style={{marginTop: "20px", textAlign:'start'}}>
                                    {content[2] && language === 'uz' ? content[2].title_uz : content[2] && content[2].title_ru}
                                </h2>
                                <div className="sep clearfix"></div>
                                <p className="sub-heading clearfix">
                                    <div dangerouslySetInnerHTML={{
                                            __html: content[2] && language === 'uz' ? content[2].content_uz : content[2] && content[2].content_ru
                                        }}></div>
                                </p>
                            </div>
                            <div className="themesflat-spacer clearfix" data-desktop="20" data-mobi="20" data-smobi="20"></div>
                            <div className="themesflat-spacer clearfix" data-desktop="21" data-mobi="20" data-smobi="20"></div>
                            <div className="themesflat-spacer clearfix" data-desktop="28" data-mobi="60" data-smobi="60"></div>
                        </div>
                        <div data-aos="flip-left" className="col-md-6 half-background style-1 psiholog"></div>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop="80" data-mobi="60" data-smobi="60"></div>
                    <div className="border-h-1 dark"></div>
                </div>
            </div>
        </div>
    );
}
export default HomeVideo;

