import NewsSingleComments from "./NewsSingleComments";
import { useTranslation } from "react-i18next";
import { API_IMG } from "../../constants/variables";
import Embed from "react-embed";

const NewsSingleSiteContent = ({ currentnews, comment }) => {
  const language = localStorage.getItem("lang");
  const { t } = useTranslation();
  const handleSingle = (name) => {
    localStorage.setItem("tegName", name);
  };

  console.log(currentnews);

  return (
    <div className="col-md-9">
      <div id="main-content" className="site-main clearfix">
        <div id="content-wrap" className="themesflat-container">
          <div id="site-content" className="site-content clearfix">
            <div id="inner-content" className="inner-content-wrap">
              <article className="hentry">
                <div className="post-content-single-wrap">
                  <div className="post-media clearfix">
                    <a href="#">
                      <img
                        style={{ width: "100%" }}
                        src={`${currentnews && API_IMG + currentnews.image}`}
                        alt="albom"
                      />
                    </a>
                  </div>
                  <div className="post-content-wrap">
                    <h2 className="post-title">
                      <span className="post-title-inner">
                        <a href="page-blog-single.html">
                          {currentnews && language === "uz"
                            ? currentnews.description_uz
                            : currentnews && currentnews.description_ru}
                        </a>
                      </span>
                    </h2>
                    <div className="post-meta style-1">
                      <div className="post-meta-content">
                        <ul className="post-date">
                          <li className="date">
                            <span className="day">
                              {" "}
                              {new Date(currentnews.created_at)
                                .toLocaleString()
                                .slice(0, 2)}{" "}
                            </span>
                          </li>
                          <li className="month">
                            {new Date(currentnews.created_at)
                              .toString()
                              .slice(4, 7)}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="post-content post-excerpt">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            currentnews && language === "uz"
                              ? currentnews.content_uz
                              : currentnews && currentnews.content_ru,
                        }}
                      ></div>
                    </div>
                    {!!currentnews.video_url_ru ? (
                      <div className="post_video">
                        <Embed
                          url={`https://www.youtube.com/watch?v=${currentnews.video_url_ru}`}
                        />
                      </div>
                    ) : null}
                    <div className="post-tags clearfix">
                      <span>{t("Comment.tag")}:</span>
                      {currentnews.tag &&
                        currentnews.tag.map((item, i) => (
                          <a
                            key={i}
                            href="/tegs"
                            onClick={() => handleSingle(item.name_uz)}
                            rel="tag"
                          >
                            {language == "uz" ? item.name_uz : item.name_ru}
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              </article>
              <NewsSingleComments post={currentnews} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsSingleSiteContent;
