import React from 'react';
import {API_IMG} from "../../../constants/variables";
import {useTranslation} from "react-i18next";

const PostsLaw = ({news, loading}) => {
    const handleSingle = (id) => {
        localStorage.setItem('newsId', id);
    }
    const language = localStorage.getItem('lang');
    const { t } = useTranslation();
    if(loading){
        return <h2>Loading...</h2>
    }
    
    return (
        <>
        {news && news.map((item) =>
          <div key={item.createId} className="col-md-4" style={{marginBottom: '10vh'}}>
              <div className="news-item style-1 clearfix">
                  <div className="inner">
                      <div className="thumb">
                          <img style={{height: '30vh', width:"100%", objectFit:'cover'}}
                                  src={`${API_IMG + item.image}`}
                                  alt="ImageNews"
                                  onClick={() => handleSingle(item.id)}/>
                          <h3 className="title"><a
                              onClick={() => handleSingle(item.id)}
                              href="/newsSingle">{language === 'uz' ? item.title_uz : item.title_ru}</a>
                          </h3>
                      </div>
                      <div className="text-wrap"
                              style={{height: '35vh'}}>

                          <p className="excerpt-text">{language === 'uz' ? item.description_uz : item.description_ru}</p>

                          <div className="post-btn">
                              <a href="/newsSingle"
                                  onClick={() => handleSingle(item.id)}
                                  className="simple-link">{t('News.read')}</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )}  
      </>
    )
}

export default PostsLaw
