import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
// import {API_IMG, API_URL} from "../../constants/variables";
import { API_URLNEW } from "../../constants/variables";
import { useTranslation } from "react-i18next";
// import Books from '../DataInformation/Books/Books';
import PostsSearch from "./PostsSearch";
import PaginationSearch from "./PaginationSearch";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Search = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [news, setNews] = useState([]);
  const [book, setBook] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  // const language = localStorage.getItem('lang')
  useEffect(() => {
    let searches = localStorage.getItem("searches");
    axios({
      method: "POST",
      url: API_URLNEW + "/search",
      params: { name: searches },
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      console.log(res.data.search_post);
      setNews(res.data.search_post);
      setBook(res.data.search_books);
    });
  }, []);

  // console.log(news);
  let pathNew = "news";
  news.forEach((n) => {
    n.kind = pathNew;
  });
  let pathBook = "book";
  book.forEach((b) => (b.kind = pathBook));
  console.log(news);
  console.log(book);
  const searchedArray = news.concat(book);
  let number = 1;
  searchedArray.forEach((i) => {
    i.createId = number++;
  });
  console.log(searchedArray);
  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = searchedArray.slice(indexOfFirstPost, indexOfLastPost);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const handleSingle = (id) => {
  //     localStorage.setItem('newsId', id);
  // }
  return (
    <div>
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div
                style={{
                  height: "100px",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                }}
              >
                {" "}
              </div>
              <div id="featured-title" className="featured-title clearfix">
                <div
                  id="featured-title-inner"
                  className="themesflat-container clearfix"
                >
                  <div className="featured-title-inner-wrap">
                    <div className="featured-title-heading-wrap">
                      <h1 className="featured-title-heading">
                        {t("News.news")}
                      </h1>
                    </div>
                    <div id="breadcrumbs">
                      <div className="breadcrumbs-inner">
                        <div className="breadcrumb-trail">
                          <a href="home.html" className="trail-begin">
                            {t("News.home")}
                          </a>
                          <span className="sep">
                            <i className="finance-icon-chevron-right"> </i>
                          </span>
                          <span className="trail-end"> {t("News.news")}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <div className="row-services bg-light-grey">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="themesflat-news" data-gap="35">
                              <PostsSearch
                                searchedArray={currentPosts}
                                loading={loading}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop="90"
                              data-mobi="60"
                              data-smobi="60"
                            >
                              <PaginationSearch
                                postsPerPage={postsPerPage}
                                totalPosts={searchedArray.length}
                                paginate={paginate}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Search;
