import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../constants/variables";
import ImgRequest from "../../image/img_2.webp";
import { ToastContainer, toast } from "react-toastify";

function HomeRequest() {
  const { t } = useTranslation();
  const [ism, setIsm] = useState("");
  const [telifon, setTelifon] = useState("");
  const [mavzu, setMavzu] = useState("");
  const [xabar, setXabar] = useState("");
  const [vaqti, setVaqti] = useState("");

  const handleChange = (e) => {
    e.preventDefault();
    console.log(ism);
    console.log(telifon);
    console.log(mavzu);
    console.log(xabar);
    console.log(vaqti);
    axios
      .post(API_URL + "/comment", { ism, telifon, mavzu, xabar, vaqti })
      .then((res) =>
        toast.success("Ma'lumot yuborildi", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      )
      .catch((err) =>
        toast.error("Hatolik!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
    setIsm("");
    setTelifon("");
    setMavzu("");
    setXabar("");
    setVaqti("");
  };
  const handleName = (e) => {
    setIsm(e.target.value);
  };
  const handlePhone = (e) => {
    setTelifon(e.target.value);
  };
  const handleSubject = (e) => {
    setMavzu(e.target.value);
  };
  const handleMessage = (e) => {
    setXabar(e.target.value);
  };
  const handleTime = (e) => {
    setVaqti(e.target.value);
  };
  return (
    <div id="request" style={{ marginBottom: "2%" }}>
      <ToastContainer />
      <div
        className="themesflat-spacer clearfix"
        data-desktop="90"
        data-mobi="40"
        data-smobi="40"
      ></div>
      <div className="row-request">
        <div className="container-fluid">
          <div className="container">
            <div className="row ">
              <div
                style={{
                  backgroundImage: `url(${ImgRequest})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "670px",
                }}
                className="col-md-6 bg-request"
              ></div>
              <div className="col-md-6 bg-light-grey">
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop="85"
                  data-mobi="40"
                  data-smobi="40"
                ></div>
                <div
                  className="themesflat-content-box clearfix "
                  data-margin="0% 10.8% 0% 10.7%"
                  data-mobimargin="0% 5% 0% 5%"
                >
                  <div className="themesflat-headings style-3 clearfix">
                    <h2 className="heading clearfix">{t("Request.title")}</h2>
                    <div className="sep clearfix"></div>
                    <p className="sub-heading clearfix">
                      {t("Request.content")}
                    </p>
                  </div>
                  <div
                    className="themesflat-spacer clearfix"
                    data-desktop="32"
                    data-mobi="40"
                    data-smobi="40"
                  ></div>
                  <div className="themesflat-contact-form style-1">
                    <form
                      onSubmit={handleChange}
                      method="post"
                      className="contact-form wpcf7-form"
                    >
                      <span className="wpcf7-form-control-wrap your-name">
                        <input
                          onChange={handleName}
                          type="text"
                          tabIndex="1"
                          id="name"
                          name="ism"
                          value={ism}
                          className="wpcf7-form-control"
                          placeholder={t("Request.name")}
                          required
                        />
                      </span>
                      <span className="wpcf7-form-control-wrap your-phone">
                        <input
                          onChange={handlePhone}
                          type="text"
                          tabIndex="2"
                          id="phone"
                          name="telifon"
                          value={telifon}
                          className="wpcf7-form-control"
                          placeholder={t("Request.phone")}
                          required
                        />
                      </span>
                      <span className="wpcf7-form-control-wrap your-select">
                        <select
                          onChange={handleTime}
                          name="vaqti"
                          value={vaqti}
                        >
                          <option value="not selected">
                            {t("Request.select")}
                          </option>
                          <option value="morning">
                            {t("Request.select1")}
                          </option>
                          <option value="afternoon">
                            {t("Request.select2")}
                          </option>
                          <option value="evening">
                            {t("Request.select3")}
                          </option>
                        </select>
                      </span>
                      <span className="wpcf7-form-control-wrap your-subject">
                        <input
                          onChange={handleSubject}
                          type="text"
                          tabIndex="3"
                          id="subject"
                          name="mavzu"
                          value={mavzu}
                          className="wpcf7-form-control"
                          placeholder={t("Request.theme")}
                          required
                        />
                      </span>
                      <span className="wpcf7-form-control-wrap your-message">
                        <textarea
                          onChange={handleMessage}
                          name="xabar"
                          tabIndex="4"
                          cols="40"
                          rows="10"
                          value={xabar}
                          className="wpcf7-form-control wpcf7-textarea"
                          placeholder={t("Request.message")}
                          required
                        />
                      </span>
                      <div className="wrap-submit">
                        <input
                          type="submit"
                          style={{ color: "white" }}
                          value={t("Request.button")}
                          className="submit  text-uppercase wpcf7-form-control wpcf7-submit"
                          id="submit"
                          name="submit"
                        />
                      </div>
                      {/*<span className="your-notification">or write us on <a*/}
                      {/*    href="/">Get in Touch</a> page.</span>*/}
                    </form>
                  </div>
                </div>
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop="90"
                  data-mobi="40"
                  data-smobi="40"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HomeRequest;
