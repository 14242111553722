import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { API_IMGBOOK } from "../../../constants/variables";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../../constants/variables";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function Foto() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [news, setNews] = useState([]);

  const language = localStorage.getItem("lang");

  useEffect(() => {
    axios({
      method: "get",
      url: API_URL + "/gcategory",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setNews(res.data.ggallery);
      console.log(news);
    });
  }, []);
  const handleSingle = (id) => {
    localStorage.setItem("gcategory_id", id);
  };
  return (
    <div>
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div
                style={{
                  height: "100px",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                }}
              >
                {" "}
              </div>
              <div id="featured-title" className="featured-title clearfix">
                <div
                  id="featured-title-inner"
                  className="themesflat-container clearfix"
                >
                  <div className="featured-title-inner-wrap">
                    <div className="featured-title-heading-wrap">
                      <h1 className="featured-title-heading">
                        {t("Foto.foto")}
                      </h1>
                    </div>
                    <div id="breadcrumbs">
                      <div className="breadcrumbs-inner">
                        <div className="breadcrumb-trail">
                          <a href="home.html" className="trail-begin">
                            {t("News.home")}
                          </a>
                          <span className="sep">
                            <i className="finance-icon-chevron-right"></i>
                          </span>
                          {/* <span className="trail-end"> {t('Foto.foto')}</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <div className="row-services bg-light-grey">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="themesflat-news" data-gap="35">
                              {news.map((item, index) => (
                                <div
                                  className="col-md-4"
                                  key={index}
                                  style={{ marginBottom: "10vh" }}
                                >
                                  <div className="news-item style-1 clearfix">
                                    <div className="inner">
                                      <div className="thumb">
                                        <img
                                          style={{
                                            height: "30vh",
                                            width: "100%",
                                            objectFit: "cover",
                                          }}
                                          src={`${API_IMGBOOK + item.image}`}
                                          alt="ImageNews"
                                          onClick={() => handleSingle(item.id)}
                                        />
                                        <h3 className="title">
                                          <a
                                            onClick={() =>
                                              handleSingle(item.id)
                                            }
                                            href="/fotoGalery"
                                          >
                                            {language === "uz"
                                              ? item.name_uz
                                              : item.name_ru}
                                          </a>
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop="90"
                              data-mobi="60"
                              data-smobi="60"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Foto;
