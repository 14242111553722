import React from 'react';
import Slider from '../../image/slideOne.jpg'
import Slider1 from '../../image/slideTwo.jpg'
import Slider2 from '../../image/slideThree.jpg'
import './home.css'
import {useTranslation} from "react-i18next";

function HomeSlider() {
    const {t} = useTranslation();
    return (
        <div>
            <div className="rev_slider_wrapper fullwidthbanner-container">
                <div id="rev-slider1" className="rev_slider fullwidthabanner">
                    <ul>
                        <li data-transition="random">
                            <img className="opacity" src={Slider} alt="albom" data-bgposition="center center" data-no-retina/>
                            <div className="tp-caption tp-resizeme text-white font-heading font-weight-700 text-center"
                                 data-x="['center','center','center','center']"
                                 data-hoffset="['-5','-5','0','0']"
                                 data-y="['middle','middle','middle','middle']"
                                 data-voffset="['-12','-12','-12','-30']"
                                 data-fontsize="['52','52','50','28']"
                                 data-lineheight="['66','66','60','35']"
                                 data-width="full"
                                 data-height="none"
                                 data-whitespace="normal"
                                 data-transform_idle="o:1;"
                                 data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                 data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                 data-mask_in="x:0px;y:[100%];"
                                 data-mask_out="x:inherit;y:inherit;"
                                 data-start="700"
                                 data-splitin="none"
                                 data-splitout="none"
                                 data-responsive_offset="on">
                                 {t('Slider.title1')} <br/> {t('Slider.title2')} <br/> {t('Slider.title3')}
                            </div>
                            <div className="tp-caption text-center"
                                 data-x="['center','center','center','center']"
                                 data-hoffset="['-5','-5','0','0']"
                                 data-y="['middle','middle','middle','middle']"
                                 data-voffset="['193','193','193','140']"
                                 data-width="full"
                                 data-height="none"
                                 data-whitespace="normal"
                                 data-transform_idle="o:1;"
                                 data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                 data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                 data-mask_in="x:0px;y:[100%];"
                                 data-mask_out="x:inherit;y:inherit;"
                                 data-start="1000"
                                 data-splitin="none"
                                 data-splitout="none"
                                 data-responsive_offset="on">
                                 <a href="#request" className="themesflat-button accent big video">{t('Slider.button')}</a>
                            </div>
                        </li>
                        <li data-transition="random">
                            <img src={Slider2}
                                 alt="albom"
                                 data-bgposition="center center"
                                 data-no-retina/>
                            <div className="tp-caption tp-resizeme text-white font-heading font-weight-700 text-center"
                                 data-x="['center','center','center','center']"
                                 data-hoffset="['-5','-5','0','0']"
                                 data-y="['middle','middle','middle','middle']"
                                 data-voffset="['-12','-12','-12','-30']"
                                 data-fontsize="['64','64','50','28']"
                                 data-lineheight="['66','66','60','35']"
                                 data-width="full"
                                 data-height="none"
                                 data-whitespace="normal"
                                 data-transform_idle="o:1;"
                                 data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                 data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                 data-mask_in="x:0px;y:[100%];"
                                 data-mask_out="x:inherit;y:inherit;"
                                 data-start="1000"
                                 data-splitin="none"
                                 data-splitout="none"
                                 data-responsive_offset="on">
                                 {t('Slider.title4')}<br/> {t('Slider.title5')}
                            </div>
                            <div className="tp-caption text-center"
                                 data-x="['center','center','center','center']"
                                 data-hoffset="['-5','-5','0','0']"
                                 data-y="['middle','middle','middle','middle']"
                                 data-voffset="['193','193','193','140']"
                                 data-width="full"
                                 data-height="none"
                                 data-whitespace="normal"
                                 data-transform_idle="o:1;"
                                 data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                 data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                 data-mask_in="x:0px;y:[100%];"
                                 data-mask_out="x:inherit;y:inherit;"
                                 data-start="1000"
                                 data-splitin="none"
                                 data-splitout="none"
                                 data-responsive_offset="on">
                                 <a href="#request" className="themesflat-button accent big video">{t('Slider.button')}</a>
                            </div>
                        </li>
                        <li data-transition="random">
                            <img src={Slider1} alt="albom" data-bgposition="center center" data-no-retina/>
                            <div className="tp-caption tp-resizeme text-white font-heading font-weight-700 text-center"
                                 data-x="['center','center','center','center']"
                                 data-hoffset="['-5','-5','0','0']"
                                 data-y="['middle','middle','middle','middle']"
                                 data-voffset="['-12','-12','-12','-30']"
                                 data-fontsize="['52','52','50','28']"
                                 data-lineheight="['66','66','60','35']"
                                 data-width="full"
                                 data-height="none"
                                 data-whitespace="normal"
                                 data-transform_idle="o:1;"
                                 data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                 data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                 data-mask_in="x:0px;y:[100%];"
                                 data-mask_out="x:inherit;y:inherit;"
                                 data-start="700"
                                 data-splitin="none"
                                 data-splitout="none"
                                 data-responsive_offset="on">
                                 {t('Slider.title7')}<br/>{t('Slider.title8')}<br/>{t('Slider.title9')}</div>
                            <div className="tp-caption text-center"
                                 data-x="['center','center','center','center']"
                                 data-hoffset="['-5','-5','0','0']"
                                 data-y="['middle','middle','middle','middle']"
                                 data-voffset="['193','193','193','140']"
                                 data-width="full"
                                 data-height="none"
                                 data-whitespace="normal"
                                 data-transform_idle="o:1;"
                                 data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                 data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                 data-mask_in="x:0px;y:[100%];"
                                 data-mask_out="x:inherit;y:inherit;"
                                 data-start="1000"
                                 data-splitin="none"
                                 data-splitout="none"
                                 data-responsive_offset="on">
                                <a href="#request" className="themesflat-button accent big video">{t('Slider.button')}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
export default HomeSlider;