const Portfolio = () => {
    return (
        <div>
            <div id="wrapper" className="animsition">
                <div id="page" className="clearfix">
                    <div id="featured-title" className="featured-title clearfix" style={{marginTop: "140px"}}>
                        <div id="featured-title-inner" className="themesflat-container clearfix">
                            <div className="featured-title-inner-wrap">
                                <div className="featured-title-heading-wrap">
                                    <h1 className="featured-title-heading">GALLERY POSTS</h1>
                                </div>
                                <div id="breadcrumbs">
                                    <div className="breadcrumbs-inner">
                                        <div className="breadcrumb-trail">
                                            <a href="home.html" className="trail-begin">Home</a>
                                            <span className="sep">
                                                <i className="finance-icon-chevron-right"></i>
                                            </span>
                                            <span className="trail-end">GALLERY POSTS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="main-content" className="site-main clearfix">
                        <div id="content-wrap">
                            <div id="site-content" className="site-content clearfix">
                                <div id="inner-content" className="inner-content-wrap">
                                    <div className="page-content">
                                        <div className="row-gallery">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="themesflat-spacer clearfix" data-desktop="90" data-mobi="60" data-smobi="60"></div>
                                                        <div className="themesflat-gallery has-arrows w32 arrows-circle arrow-top arrow30"
                                                             data-auto="false" data-column="3"
                                                             data-column2="2"
                                                             data-column3="1"
                                                             data-gap="30">
                                                            <div className="owl-carousel owl-theme">
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery1.jpg" alt="ImgPortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery1.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery2.jpg" alt="ImagePortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery2.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery3.jpg" alt="ImagePortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery3.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery1.jpg" alt="ImagePortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery1.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery2.jpg" alt="ImagePortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery2.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery3.jpg" alt="ImagePortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery3.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="themesflat-spacer clearfix" data-desktop="52" data-mobi="40" data-smobi="40"></div>
                                                        <div className="themesflat-align-box text-center">
                                                            <div className="button-wrap icon-left">
                                                                <a href="/" className="themesflat-button outline big light">SEE ALL PROJECTS</a>
                                                            </div>
                                                        </div>
                                                        <div className="themesflat-spacer clearfix" data-desktop="60" data-mobi="40" data-smobi="40"></div>
                                                        <div className="themesflat-divider divider-center divider-solid clearfix"></div>
                                                        <div className="themesflat-spacer clearfix" data-desktop="60" data-mobi="40" data-smobi="40"></div>
                                                        <div className="themesflat-gallery  bullet-circle has-bullets"
                                                             data-auto="false" data-column="3"
                                                             data-column2="2"
                                                             data-column3="1"
                                                             data-gap="30">
                                                            <div className="owl-carousel owl-theme">
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery1.jpg" alt="ImagePortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery1.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery2.jpg" alt="ImagePortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery2.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery3.jpg" alt="ImagePortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery3.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery1.jpg" alt="ImagePortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery1.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery2.jpg" alt="ImagePortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery2.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery3.jpg" alt="ImagePortFolio"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery3.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="themesflat-spacer clearfix" data-desktop="90" data-mobi="60" data-smobi="60"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row-promotion bg-accent">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="themesflat-spacer clearfix" data-desktop="24" data-mobi="40" data-smobi="40"></div>
                                                        <div className="themesflat-action-box style-1 has-icon padding-left-110 padding-right-113">
                                                            <div className="inner">
                                                                <div className="heading-wrap">
                                                                    <div className="text-wrap">
                                                                        <span className="icon finance-icon-award"></span>
                                                                        <h3 className="heading mobi-padding-top20 mobi-padding-bottom20">PROFESSIONAL HELP IN PLANNING YOUR FINANCIAL FUTURE</h3>
                                                                        <span className="icon">
                                                                            <i className="as-icon-speedometer2"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="button-wrap">
                                                                    <a href="/" className="themesflat-button white font-weight-600 margin-top-10 margin-bottom-13">CONTACT US TODAY</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="themesflat-spacer clearfix" data-desktop="20" data-mobi="40" data-smobi="40"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Portfolio;