import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {useTranslation} from "react-i18next";
import axios from 'axios';
import { API_URL } from '../../constants/variables';

const useStyles = makeStyles((theme) => ({
    modal: {
        width: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function TestimonialsModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [action, setAction] = useState('')
    const {t} = useTranslation();
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleComment = (e) =>{
        e.preventDefault()
        axios.post(API_URL+'/comment',{name ,message}).then(res=>console.log(res));
        setOpen(!open)
    }
    const handleChange = (e) => {
        setName(e.target.value);
    }
    const handlePhone = (e) => {
        setPhone(e.target.value)
    }
    const handleComentary = (e) =>{
        setMessage(e.target.value)
    }
    const handleSelect = e =>{
        setAction(e.target.value)
    }
    return (
        <div>
            <button onClick={handleOpen} style={{color: 'white'}} type="button" >{props.text}</button>
            <Modal aria-labelledby="transition-modal-title"
                   aria-describedby="transition-modal-description"
                   className={classes.modal}
                   open={open}
                   onClose={handleClose}
                   closeAfterTransition
                   BackdropComponent={Backdrop}
                   BackdropProps={{timeout: 500,}}>
                <Fade in={open}>
                    <div style={{
                                  width: '500px',
                                  backgroundColor: 'white',
                                  padding: '20px',
                                  borderRadius: '10px'
                                }}>
                        <div class="themesflat-contact-form style-1 color-light-grey">
                            <form onSubmit={handleComment} method="post" class="contact-form wpcf7-form">
                                <span class="wpcf7-form-control-wrap your-name">
                                    <input onChange={handleChange}
                                           type="text"
                                           tabindex="1"
                                           id="name"
                                           name="name"
                                           value={name}
                                           class="wpcf7-form-control"
                                           placeholder={t('Request.name')}
                                           required/>
                                </span>
                                <span class="wpcf7-form-control-wrap your-phone">
                                    <input onChange={handlePhone}
                                           type="text"
                                           tabindex="2"
                                           id="phone"
                                           name="phone"
                                           value={phone}
                                           class="wpcf7-form-control"
                                           placeholder={t('Request.phone')}
                                           required/>
                                </span>
                                <span class="wpcf7-form-control-wrap your-select">
                                    <select onChange={handleSelect} name="action" value={action}>
                                        <option >{t('PopUp.select')} &#9733; &#9733; &#9733;</option>
                                        <option >{t('PopUp.select')} &#9733; &#9733; &#9733; &#9733;</option>
                                        <option >{t('PopUp.select')} &#9733; &#9733; &#9733; &#9733; &#9733;</option>
                                    </select>
                                </span>
                                <span class="wpcf7-form-control-wrap your-message">
                                    <textarea onChange={handleComentary}
                                              name="message"
                                              tabindex="4"
                                              cols="40"
                                              rows="10"
                                              value={message}
                                              class="wpcf7-form-control wpcf7-textarea"
                                              placeholder={t('PopUp.message')}
                                              required></textarea>
                                </span>
                                <div class="wrap-submit">
                                    <input type="submit"
                                           value={t('Request.button')}
                                           class="submit wpcf7-form-control wpcf7-submit"
                                           id="submit"
                                           name="submit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}