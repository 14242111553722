import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import {API_URL} from "../../constants/variables";

const NewsSingleComments = ({post}) => {
    const {t} = useTranslation();
    const categoryId = localStorage.getItem('categoryId')
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState([]);
    let test;
    if (post != undefined) {
        test = post.comment
    }
    // useEffect(() => {
    //     setTimeout(() => {
    //         if (post != undefined) {
    //             test = post.comment
    //         }
    //     }, 1000)
    // }, [])
    useEffect(() => {
        setTimeout(() => {
            setComment(test)
        }, 2000)
    }, [test])
    console.log(comment)
    const handleSend = (e) => {
        e.preventDefault();
        axios({
            method: 'POST',
            url: API_URL + '/comment',
            data: {
                post_id: post && post.id,
                name, email, message,
            }
        }).then(res => {
            if (res.data.status == "success") {
                axios.get(API_URL + "/posts/" + categoryId).then(res => {
                    setComment(res.data.posts[0].comment)
                    setEmail('')
                    setName('')
                    setMessage('')
                })
            }
        })
    }
    const changeName = (e) => {
        setName(e.target.value)
    }
    const changeEmail = (e) => {
        setEmail(e.target.value)
    }
    const changeMessage = (e) => {
        setMessage(e.target.value)
    }
    return (
        <div id="comments" className="comments-area">
            <h2 className="comments-title">{t('Comment.title1')}</h2>
            <ol className="comment-list">
                {comment && comment.map((item, i) =>
                    <li className="comment">
                        <article className="comment-wrap clearfix">
                            <div className="gravatar">
                                <img alt="albom" src="assets/img/avatar.webp"/>
                            </div>
                            <div className="comment-content">
                                <div className="comment-meta">
                                    <h6 className="comment-author">{item.name}</h6>
                                    <span className="comment-time">{item.created_at.slice(0, 10)}</span>
                                    <span className="comment-reply">
                                        <a className="comment-reply-link" href="#">{t('Comment.answer')}</a>
                                    </span>
                                </div>
                                <div className="comment-text">
                                    <p>{item.message}</p>
                                </div>
                            </div>
                        </article>
                    </li>
                )}
            </ol>
            <div id="respond" className="comment-respond">
                <h3 id="reply-title" className="comment-reply-title">{t('Comment.title')}</h3>
                <form onSubmit={handleSend} id="commentform" className="comment-form">
                    <fieldset className="message-wrap">
                        <textarea onChange={changeMessage}
                                  id="comment-message"
                                  value={message}
                                  name="comment"
                                  rows="8" tabIndex="4"
                                  placeholder={t('Comment.sms')}></textarea>
                    </fieldset>
                    <fieldset className="name-wrap">
                        <input onChange={changeName}
                               type="text"
                               id="name"
                               className="tb-my-input"
                               name="name"
                               tabIndex="1"
                               value={name}
                               placeholder={t('Comment.name')}
                               size="32"
                               aria-required="true"/>
                    </fieldset>
                    <fieldset className="email-wrap">
                        <input onChange={changeEmail}
                               type="text" id="email"
                               className="tb-my-input"
                               name="email"
                               value={email}
                               tabIndex="2"
                               placeholder={t('Comment.email')} size="32"
                               aria-required="true"/>
                    </fieldset>
                    <p className="form-submit">
                        <input name="submit"
                               type="submit"
                               id="comment-reply"
                               className="submit"
                               value={t('Request.button')}/>
                        <input type="hidden"
                               name="post_id"
                               value={post && post.id}
                               id="post_id"/>
                    </p>
                </form>
            </div>
        </div>
    );
}
export default NewsSingleComments;