import React from 'react';

const PaginationMilkBaby = ({postsPerPage, totalPosts, paginate}) => {
    const pageNumber = [];
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++){
        pageNumber.push(i);
    }
    return(
        <nav className="text-center">
            <ul className="pagination">
                {pageNumber.map(number => (
                    <li key={number} className="page-item">
                        <a onClick={() => paginate(number)} href="#" className="page-link">
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    )
}
export default PaginationMilkBaby;