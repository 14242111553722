import NewsSingleFeaturedTitle from "./NewsSingleFeaturedTitle";
// import NewsSingleHeroSection from "./NewsSingleHeroSection";
import NewsSingleSideBar from "./NewsSingleSideBar";
import NewsSingleSiteContent from "./NewsSingleSiteContent";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../constants/variables";

const NewsSingle = () => {
  const newsId = localStorage.getItem("newsId");
  const language = localStorage.getItem("lang");
  const category = localStorage.getItem("categoryId");
  const [currentNews, setCurrentNews] = useState({});
  const [comment, setComment] = useState({});
  useEffect(() => {
    axios.get(API_URL + "/post/" + newsId).then((res) => {
      setCurrentNews(res.data.details);
      setComment(res.data.comment);
    });
  }, []);

  return (
    <div>
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="content-wrap">
            {/* <NewsSingleHeroSection/> */}
            <div
              style={{ height: "100px", backgroundColor: "rgba(0, 0, 0, 0.7)" }}
            ></div>
            <NewsSingleFeaturedTitle />
            <div id="main-content" className="site-main clearfix">
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <div className="row-accordion">
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop="91"
                              data-mobi="60"
                              data-smobi="60"
                            ></div>
                          </div>
                        </div>
                        <div className="row">
                          <NewsSingleSiteContent
                            comment={comment}
                            currentnews={currentNews}
                          />
                          <NewsSingleSideBar />
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop="91"
                              data-mobi="60"
                              data-smobi="60"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsSingle;
