import NewsSingleComments from "../../NewsSingle/NewsSingleComments";
import {API_IMG} from "../../../constants/variables";
import {useTranslation} from "react-i18next";

const MedicalSiteContent = ({post}) => {
    const language = localStorage.getItem('lang')
    const {t} = useTranslation();
    if (post == undefined) {
        localStorage.setItem('categoryId', 2);
    }
    return (
        <div className="col-md-9">
            {post != undefined ?
                <div id="main-content" className="site-main clearfix">
                    <div id="content-wrap" className="themesflat-container">
                        <div id="site-content" className="site-content clearfix">
                            <div id="inner-content" className="inner-content-wrap">
                                <article className="hentry">
                                    <div className="post-content-single-wrap">
                                        <div className="post-media clearfix">
                                            <a href="#">
                                                <img src={`${API_IMG + post.image}`} alt="albom"/>
                                            </a>
                                        </div>
                                        <div className="post-content-wrap">
                                            <h2 className="post-title">
                                            <span className="post-title-inner">
                                                <a href="page-blog-single.html">{post && language === 'uz' ? post.title_uz : post && post.title_ru}</a>
                                            </span>
                                            </h2>
                                            <div className="post-meta style-1">
                                                <div className="post-meta-content">
                                                    <ul className="post-date">
                                                        <li className="date">
                                                            <span className="day"> {new Date(post.created_at).toLocaleString().slice(0, 2)} </span>
                                                        </li>
                                                        <li className="month">
                                                            {new Date(post.created_at).toString().slice(4, 7)}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="post-content post-excerpt">
                                                <div dangerouslySetInnerHTML={{
                                                        __html: post && language === 'uz' ? post.content_uz : post && post.content_ru
                                                    }}></div>
                                            </div>
                                            <div className="post-tags clearfix">
                                                <span>{t('Comment.tag')}:</span>
                                                {post.tag && post.tag.map((item, i) =>
                                                    <a key={i} href="/tegs" rel="tag">{language === 'uz' ? item.name_uz : item.name_ru}</a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </article>
                                <NewsSingleComments post={post}/>
                            </div>
                        </div>
                    </div>
                </div>
                : <h1>Data Not Found</h1>}
        </div>
    );
}
export default MedicalSiteContent;