const Project1 = () => {
    return (
        <div>
            <div id="wrapper" className="animsition">
                <div id="page" className="clearfix">
                    <div id="featured-title" className="featured-title clearfix" style={{marginTop: "140px"}}>
                        <div id="featured-title-inner" className="themesflat-container clearfix">
                            <div className="featured-title-inner-wrap">
                                <div className="featured-title-heading-wrap">
                                    <h1 className="featured-title-heading">PORTFOLIO</h1>
                                </div>
                                <div id="breadcrumbs">
                                    <div className="breadcrumbs-inner">
                                        <div className="breadcrumb-trail">
                                            <a href="home.html" className="trail-begin">Home</a>
                                            <span className="sep">
                                                <i className="finance-icon-chevron-right"></i>
                                            </span>
                                            <span className="trail-end">PORTFOLIO</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="main-content" className="site-main clearfix">
                        <div id="content-wrap">
                            <div id="site-content" className="site-content clearfix">
                                <div id="inner-content" className="inner-content-wrap">
                                    <div className="page-content">
                                        <div className="row-portfolio">
                                            <div className="container">
                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <div className="themesflat-spacer clearfix" data-desktop="102" data-mobi="60" data-smobi="60"></div>
                                                    </div>
                                                </div>
                                                <div className="row clearfix">
                                                    <div className="col-md-8">
                                                        <div className="themesflat-content-box clearfix" data-margin="0px 9px 0px 0px" data-mobimargin="0 0 0 0">
                                                            <div className="themesflat-gallery clearfix">
                                                                <div className="gallery-item">
                                                                    <div className="inner">
                                                                        <div className="gallery-image">
                                                                            <img src="assets/img/gallery/gallery-portfolio-1-870x480.jpg" alt="Imageproject1"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="themesflat-spacer clearfix"
                                                                     data-desktop="35"
                                                                     data-mobi="35"
                                                                     data-smobi="35"></div>
                                                                <div className="gallery-item">
                                                                    <div className="inner">
                                                                        <div className="gallery-image">
                                                                            <img src="assets/img/gallery/gallery-portfolio-2-870x480.jpg" alt="Imageproject1"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="themesflat-spacer clearfix" data-desktop="0" data-mobi="30" data-smobi="30"></div>
                                                        <div className="themesflat-content-box clearfix" data-margin="0px 0px 0px 9px" data-mobimargin="0 0 0 0">
                                                            <div className="themesflat-portfolio-detail style-1">
                                                                <div className="inner">
                                                                    <div className="text-wrap">
                                                                        <h6 className="title">
                                                                            <span>PROJECT DETAIL</span></h6>
                                                                        <div className="desc">
                                                                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit sed diam nonummy nibh euismod tincid unt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent </p>
                                                                            <p>luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore</p>
                                                                        </div>
                                                                        <ul className="portfolio-meta">
                                                                            <li> CLIENT <span>LUXURY BUILDING</span> </li>
                                                                            <li>
                                                                                CATEGORY <span> <a href="/">MODERN,</a> <a href="/">FINANCE</a></span>
                                                                            </li>
                                                                            <li>
                                                                                DATE <span> <a href="/">08 MAY 2018</a></span>
                                                                            </li>
                                                                            <li>
                                                                                LINK <span><a href="/">FINANCE</a></span>
                                                                            </li>
                                                                        </ul>
                                                                        <div className="elm-btn">
                                                                            <a href="/" className="themesflat-button azure font-weight-600">LIVE PREVIEW</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <div className="themesflat-spacer clearfix" data-desktop="109" data-mobi="60" data-smobi="60"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row-gallery bg-light-grey">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="themesflat-spacer clearfix" data-desktop="83" data-mobi="60" data-smobi="60"></div>
                                                        <div className="themesflat-headings style-1 clearfix">
                                                            <h2 className="heading clearfix">BEST PROJECTS</h2>
                                                            <div className="sep clearfix"></div>
                                                        </div>
                                                        <div className="themesflat-spacer clearfix" data-desktop="39" data-mobi="40" data-smobi="40"></div>
                                                        <div className="themesflat-gallery has-arrows w32 arrows-circle arrow-top arrow30"
                                                             data-auto="false"
                                                             data-column="3"
                                                             data-column2="2"
                                                             data-column3="1"
                                                             data-gap="30">
                                                            <div className="owl-carousel owl-theme">
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery1.jpg" alt="Imageproject1"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery1.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery2.jpg" alt="Imageproject1"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery2.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery3.jpg" alt="Imageproject1"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery3.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery1.jpg" alt="Imageproject1"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery1.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery2.jpg" alt="Imageproject1"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery2.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="gallery-box">
                                                                    <div className="inner">
                                                                        <div className="hover-effect">
                                                                            <div className="gallery-image">
                                                                                <img src="assets/img/gallery/gallery3.jpg" alt="Imageproject1"/>
                                                                            </div>
                                                                            <div className="text">
                                                                                <div className="icon">
                                                                                    <a className="zoom-popup" href="assets/img/gallery/gallery3.jpg">
                                                                                        <i className="flat-icon-magnifying-glass-zoom-in"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="themesflat-spacer clearfix" data-desktop="52" data-mobi="40" data-smobi="40"></div>
                                                        <div className="themesflat-align-box text-center">
                                                            <div className="button-wrap icon-left">
                                                                <a href="/" className="themesflat-button outline big light">SEE ALL PROJECTS</a>
                                                            </div>
                                                        </div>
                                                        <div className="themesflat-spacer clearfix" data-desktop="75" data-mobi="60" data-smobi="60"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Project1;