import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import {API_URL, API_IMG} from "../../constants/variables";
import {useTranslation} from "react-i18next";

function HomeNews() {
    const {t} = useTranslation();
    const [news, setNews] = useState([]);
    const language = localStorage.getItem('lang')
    const [newsId, setNewsId] = useState({});
    const [sliderCount, setSliderCount] = useState('3');
    useEffect(() => {
        axios.get(API_URL + "/category").then(res => {
            if (language === 'ru') {
                setNewsId(res.data.category.find(item => item.name_ru.toLowerCase() == 'Новости'.toLowerCase()));
            } else {
                setNewsId(res.data.category.find(item => item.name_uz.toLowerCase() == 'Yangiliklar'.toLowerCase()));
            }
        })
    }, [])
    useEffect(() => {
        window.onscroll = () => {
            if (window.innerWidth < 800) {
                setSliderCount(2)
            }
            if (window.innerWidth < 550) {
                setSliderCount(1)
            }
            if (window.innerWidth > 800) {
                setSliderCount(3)
            }
        }
    });
    useEffect(() => {
        setTimeout(() => {
            axios({
                method: 'get',
                url: API_URL + "/posts/" + newsId.id,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                setNews(res.data.posts);
            })
        }, 1000)
    }, [])

    const writeId = (id) => {
        localStorage.setItem('newsId', id);
    };
    return (
        <div>
            <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                    <div className="page-content">
                        <div className="row-services">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="themesflat-spacer clearfix" data-desktop="90" data-mobi="60" data-smobi="60"></div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="themesflat-news has-arrows w32 arrows-circle arrow-top arrow30"
                                             data-auto="false" data-column="3" data-column2="2"
                                             data-column3="1"
                                             data-gap="30">
                                             <Swiper spaceBetween={50}
                                                     slidesPerView={sliderCount}
                                                     navigation
                                                     pagination={{clickable: true}}
                                                     scrollbar={{draggable: true}}
                                                     onSwiper={(swiper) => console.log(swiper)}
                                                     onSlideChange={() => console.log('')}
                                             >
                                                 <div className="owl-carousel owl-theme">
                                                    {news && news.map((item, i) =>
                                                        <SwiperSlide key={i}>
                                                            <div className="news-item clearfix">
                                                                <div className="inner"
                                                                     style={{
                                                                             border: "1px solid #d9d9d9",
                                                                             height: '540px'
                                                                            }}>
                                                                    <div className="thumb">
                                                                        <img style={{height: '220px', width: '100%'}}
                                                                             src={API_IMG + item.image}
                                                                             alt="ImageNews"/>
                                                                        <div className="meta">
                                                                            <span className="post-events">СОБЫТИЕ</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="text-wrap">
                                                                        <h3 className="title">
                                                                            <a href="/newsSingle" onClick={() => writeId(item.id)}>
                                                                                {language === 'uz' ? item.title_uz : item.title_ru}
                                                                            </a>
                                                                        </h3>
                                                                        <p className="excerpt-text">{language === 'uz' ? item.description_uz : item.description_ru}</p>
                                                                        <div className="post-btn">
                                                                            <a href="/newsSingle"
                                                                               onClick={() => writeId(item.id)}
                                                                               className="small themesflat-button outline ol-accent">{t('News.read')}</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    )}
                                                </div>
                                            </Swiper>
                                        </div>
                                    </div>
                                    <div className="themesflat-spacer clearfix" data-desktop="80" data-mobi="60" data-smobi="60"></div>
                                    <div className="border-h-1 dark"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HomeNews;