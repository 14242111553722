import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../constants/variables";

export default function FooterTegs() {
  const { t } = useTranslation();
  const language = localStorage.getItem("lang");
  const [tags, setTags] = useState([]);
  const [press, setPress] = useState([]);

  useEffect(() => {
    axios.get(API_URL + "/tags").then((res) => {
      setTags(res.data.tag);
    });
  }, []);

  const sorted = [];
  for (let i = 0; i < tags.length; i++) {
    sorted.push(tags[i]?.name_uz?.toLowerCase());
  }
  const sortedRu = [];
  for (let r = 0; r < tags.length; r++) {
    sortedRu.push(tags[r]?.name_ru?.toLowerCase());
  }
  const uniqueSort = [...new Set(sorted)];
  const uniqueSortRu = [...new Set(sortedRu)];

  const handleSingle = (name) => {
    localStorage.setItem("tegName", name);
  };
  return (
    <div className="span_1_of_4 col">
      <div className="widget widget_tag_cloud margin-top-6 padding-left-9">
        <h2 className="widget-title margin-bottom-43">
          <span>{t("Footer.tag")}</span>
        </h2>
        <div className="tagcloud">
          {uniqueSort &&
            uniqueSort.map((item, i) => {
              if (i > 0) {
                return (
                  <a key={i} href="/tegs" onClick={() => handleSingle(item)}>
                    {item}
                  </a>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}
