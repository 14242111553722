const ServiceRowFacts1 = () => {
    return (
        <div className="row-facts-1 parallax parallax parallax-overlay">
            <div className="bg-parallax-overlay overlay-black"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="themesflat-spacer clearfix" data-desktop="72" data-mobi="40" data-smobi="40"></div>
                        <div className="themesflat-headings style-1 color-white text-center clearfix">
                            <h2 className="heading clearfix">COMPANY FACTS</h2>
                            <div className="sep clearfix"></div>
                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop="34" data-mobi="40" data-smobi="40"></div>
                    </div>
                    <div className="col-md-4 border-left-1 border-top-1 border-bottom-1 azure1">
                        <div className="themesflat-spacer clearfix" data-desktop="40" data-mobi="0" data-smobi="0"></div>
                        <div className="themesflat-animation-block" data-animate="fadeInUpSmall" data-duration="1s" data-delay="0.15s" data-position="80%">
                            <div className="themesflat-counter style-5 icon-left clearfix text-center">
                                <div className="inner">
                                    <div className="icon-wrap">
                                        <div className="icon">
                                            <i className="finance-icon-bulb"></i>
                                        </div>
                                    </div>
                                    <div className="text-wrap">
                                        <div className="number-wrap font-heading">
                                            <span className="prefix"></span>
                                            <span className="number accent" data-speed="3000" data-to="7513" data-inviewport="yes">7513</span>
                                            <span className="suffix">+</span>
                                        </div>
                                        <div className="sep"></div>
                                        <h3 className="heading">Happy Clients</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop="25" data-mobi="0" data-smobi="0"></div>
                    </div>
                    <div className="col-md-4 border-left-1 border-top-1 border-right-1 border-bottom-1 azure1">
                        <div className="themesflat-spacer clearfix" data-desktop="40" data-mobi="40" data-smobi="40"></div>
                        <div className="themesflat-animation-block" data-animate="fadeInUpSmall" data-duration="1s" data-delay="0.3s" data-position="80%">
                            <div className="themesflat-counter style-5 icon-left clearfix text-center">
                                <div className="inner">
                                    <div className="icon-wrap">
                                        <div className="icon">
                                            <i className="finance-icon-businessman-1"></i>
                                        </div>
                                    </div>
                                    <div className="text-wrap">
                                        <div className="number-wrap font-heading">
                                            <span className="prefix"></span>
                                            <span className="number accent" data-speed="3000" data-to="1290" data-inviewport="yes">1290</span>
                                            <span className="suffix">+</span>
                                        </div>
                                        <div className="sep"></div>
                                        <h3 className="heading">Expert Advisors</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop="25" data-mobi="0" data-smobi="0"></div>
                    </div>
                    <div className="col-md-4 border-top-1 border-right-1 border-bottom-1 azure1">
                        <div className="themesflat-spacer clearfix" data-desktop="40" data-mobi="40" data-smobi="40"></div>
                        <div className="themesflat-animation-block" data-animate="fadeInUpSmall" data-duration="1s" data-delay="0.45s" data-position="80%">
                            <div className="themesflat-counter style-5 icon-left clearfix text-center">
                                <div className="inner">
                                    <div className="icon-wrap">
                                        <div className="icon">
                                            <i className="finance-icon-award"></i>
                                        </div>
                                    </div>
                                    <div className="text-wrap">
                                        <div className="number-wrap font-heading">
                                            <span className="prefix"></span>
                                            <span className="number accent" data-speed="3000" data-to="2684" data-inviewport="yes">2684</span>
                                            <span className="suffix">+</span>
                                        </div>
                                        <div className="sep"></div>
                                        <h3 className="heading">Project Success</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="themesflat-spacer clearfix" data-desktop="25" data-mobi="0" data-smobi="0"></div>
                    </div>
                    <div className="col-md-12">
                        <div className="themesflat-spacer clearfix" data-desktop="80" data-mobi="60" data-smobi="60"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ServiceRowFacts1;