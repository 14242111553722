import React, { useEffect, useState } from 'react';
// import NewsSingleHeroSection from "../NewsSingle/NewsSingleHeroSection";
import HomeRequest from "../Home/HomeRequest";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL } from "../../constants/variables";
import ImgIconBaby from "../../image/healthy/baby.svg"
import ImgIconCouple from "../../image/healthy/couple.svg"
import ImgIconInformation from "../../image/healthy/information.svg"
import ImgIconMother from "../../image/healthy/mother.svg"

function Health(props) {
    const { t } = useTranslation();
    const language = localStorage.getItem('lang')
    const [subMenu, setSubMenu] = useState([]);

    useEffect(() => {
        axios.get(API_URL + "/category").then(res => {
            setSubMenu(res.data.category);
        })
    }, [])
    console.log(subMenu)

    const getByCategory = (name) => {
        console.log(name)
        let res;
        if (language === 'ru') {
            res = subMenu.find(item => item.name_ru.toLowerCase() === name.toLowerCase())
        } else {
            res = subMenu.find(item => item.name_uz.toLowerCase() === name.toLowerCase())
            // localStorage.setItem('categoryId', result.id);
        }
        localStorage.setItem('categoryId', res.id);
    }
    return (
        <div>
            <div className="hero-section slideshow text-center" data-height="840" data-count="2"
                data-image="./assets/img/health/health.webp" data-effect="fade" data-overlay="" data-poverlay="">
                <div className="hero-content">
                    <div className="themesflat-fancy-text typed" data-fancy={t('Baby.slider2')}>
                        <h1 style={{background:'transparent'}} className="heading font-weight-700 letter-spacing--2">
                            {t('Baby.slider1')}
                            <span className="text">{t('Baby.slider2')}</span>
                        </h1>
                    </div>
                    <div className="themesflat-spacer clearfix" data-desktop="21" data-mobi="21" data-smobi="21"></div>
                    <div className="elm-btn">
                        <a href="#request" className="themesflat-button accent">{t('Slider.button')}</a>
                    </div>
                </div>
                <a className="arrow scroll-target" href="#content"></a>
            </div>
            <div id="main-content" className="site-main clearfix">
                <div id="content-wrap">
                    <div id="site-content" className="site-content clearfix">
                        <div id="inner-content" className="inner-content-wrap">
                            <div id="content" className="row-services">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="themesflat-spacer clearfix" data-desktop="91" data-mobi="60" data-smobi="60"></div>
                                            <div className="themesflat-headings style-1 clearfix text-center">
                                                <h2 className="heading clearfix">{t('Health.title')}</h2>
                                                <div className="sep clearfix"></div>
                                                <p className="sub-heading clearfix">{t('Health.content')}</p>
                                            </div>
                                            <div className="themesflat-spacer clearfix" data-desktop="52" data-mobi="40" data-smobi="40"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 border-bottom-1 dark">
                                                <div className="themesflat-icon-box style-1 clearfix w70 text-center rounded-100 has-width padding-left-36 padding-right-23">
                                                    <a style={{ height: '60px', width: '60px' }} href="/marriage">
                                                        <div className="icon-wrap">
                                                            <i>
                                                                <img src={ImgIconCouple} />
                                                            </i>
                                                        </div>
                                                    </a>
                                                    <h3 className="heading text-uppercase">
                                                        <a onClick={() => getByCategory(t('Menu.menu15'))} href="/marriage">{t('Menu.menu15')}</a>
                                                    </h3>
                                                    <p className="desc">{t('Health.content1')}</p>
                                                </div>
                                                <div className="themesflat-spacer clearfix" data-desktop="36" data-mobi="40" data-smobi="40"></div>
                                            </div>
                                            <div className="col-md-6 border-left-1 border-right-1 border-bottom-1 dark">
                                                <div className="themesflat-icon-box style-1 clearfix w70 text-center rounded-100 has-width padding-left-36 padding-right-30">
                                                    <a style={{ height: '60px', width: '60px' }} href="/periodPregnancy">
                                                        <div className="icon-wrap">
                                                            <i>
                                                                <img src={ImgIconMother} />
                                                            </i>
                                                        </div>
                                                    </a>
                                                    <h3 className="heading text-uppercase">
                                                        <a href="/periodPregnancy">{t('Health.title2')}</a>
                                                    </h3>
                                                    <p className="desc">{t('Health.content2')}</p>
                                                </div>
                                                <div className="themesflat-spacer clearfix" data-desktop="36" data-mobi="40" data-smobi="40"></div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="themesflat-spacer clearfix" data-desktop="32" data-mobi="40" data-smobi="40"></div>
                                                <div className="themesflat-icon-box style-1 clearfix w70 text-center rounded-100 has-width padding-left-36 padding-right-23">
                                                    <a style={{ height: '60px', width: '60px' }} href="/baby">
                                                        <div className="icon-wrap">
                                                            <i>
                                                                <img src={ImgIconBaby} />
                                                            </i>
                                                        </div>
                                                    </a>
                                                    <h3 className="heading text-uppercase">
                                                        <a href="/baby">{t('Health.title3')}</a>
                                                    </h3>
                                                    <p className="desc">{t('Health.content3')}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 border-left-1 border-right-1 dark">
                                                <div className="themesflat-spacer clearfix" data-desktop="32" data-mobi="40" data-smobi="40"></div>
                                                <div className="themesflat-icon-box style-1 clearfix w70 text-center rounded-100 has-width padding-left-36 padding-right-30">
                                                    <a style={{ height: '60px', width: '60px' }} href="/healthLife">
                                                        <div className="icon-wrap">
                                                            <i>
                                                                <img src={ImgIconInformation} />
                                                            </i>
                                                        </div>
                                                    </a>
                                                    <h3 className="heading text-uppercase">
                                                        <a href="/healthLife">{t('Health.title4')}</a>
                                                    </h3>
                                                    <p className="desc">{t('Health.content4')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <HomeRequest id="request" />
                            <div className="col-md-12">
                                <div className="themesflat-spacer clearfix" data-desktop="96" data-mobi="60" data-smobi="60"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Health;